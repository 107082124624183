.dimensionVisualizer {
    
    border: 3px solid black;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#searchingBar {
    height: 170px;
    margin-bottom: 30px;
}

#functionBar {
    height: 754px;
}

/* .mainDBArea {
    height: 100px;
    margin-bottom: 10px;
} */

.tutorDisplayArea {
    overflow-y: scroll;
    /* height: 754px; */
    height: 61vh;
   
    
}
.memTableHead {
    /* border: 1px solid white; */
    /* border-radius: 5px; */
    /* background: white; */
    height: 60px;
    width:99%;
    /* margin-bottom: 10px; */
    font-weight: 700;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    transition: 0.3s;
    margin-left: 0.55%;

}

.tutorSimplifiedCard {
    border: 1px solid white;
    border-radius: 2px;
    background: white;
    /* height: 60px; */
    width:99%;
    margin-bottom: 9px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    transition: 0.1s;
    margin-left: 0.55%;
    box-shadow: 0px 15px 20px rgba(202, 202, 202, 0.2);

}
.tutorSimplifiedCard:hover{
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
}

.tutorSimInfo {
    display: inline-block;
    margin: 0px;
}
.tutorID{
    /* width: calc(10% - 10px); */
    width:7%;
}
.nameChi{
    width:13%;
}
.nameEng{
    width:13%;
    display: flex;
    align-items: center;
}
.sex{
    width:10%;
}
.lilSpace{
    margin-left: 10px;
}
.memStatus{
    width:17%;
    /* width: 37%; */
}
.danceType{
    width:10%;
}
.lastActivity {
    width: 20%;
}
/* .circleInitial {
    border-radius: 50%;
    background-color: sandybrown;
    height:40px;
    width:40px;
    background-color: sandybrown;
    display: inline-block;
    margin-right: 5px;;
} */
.tutorName{
    display: inline-block;
}
.manageBtn {
    /* width: 12%; */
    display: flex;
    justify-content: flex-end;
}
.payrollBtn {
    /* width: 8%; */
    display: flex;
    justify-content: flex-end;
}

#lastActivity{
    display: inline-block;
}

#sevenDays {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    color: rgb(119, 118, 118);
}

.inlineDisplay{
    display: inline-block;
    margin-right: 14px;
}
#sectionHeading {
    margin-left: 9.7px;
    margin-bottom: 30px;
    margin-top: 30px;
    display: inline-block;
}
.topClass {
    position:fixed;
    top:0px;
    right:0px;
    width: 100%;
    height: 100%;
   
    z-index: 1;
    text-align:center;
    background: rgba(0, 0, 0, 0.8);
}
.classDisplayInArea{
    position: fixed;
    top: 10%;
    right: 5%;
    margin: auto;
    background:white;
    width: 90%;
    height: 80%;
    z-index: 2;
    border-radius: 5px;
}
.topClass2 {
    position:fixed;
    top:0px;
    right:0px;
    width: 100%;
    height: 100%;
   
    z-index: 3;
    text-align:center;
    background: rgba(0, 0, 0, 0.8);
}
.displayInArea2{
    position: fixed;
    top: 5%;
    right: 5%;
    margin: auto;
    background:white;
    width: 90%;
    height: 90%;
    z-index: 4;
    border-radius: 5px;
    /* overflow-x: scroll; */
}
.aligner{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-right: 50px; */
}
.flexEnd{
    display: flex;
    justify-content: center;
}
.customBtn{
    font-weight: 700;
    font-size: 120%;
    margin-left: 10px;
}
#newTutorBtn{
    height:80%;
    /* width: 15%; */
    /* margin: 20px; */
}
#tableHeader{
    border: 1px solid white;
    width:100%;
}
/* .tutorSimplifiedHeadCard {
    border: 1px solid white;
    border-radius: 2px;
    background: white;
    height: 60px;
   
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    
    align-items: center;
    transition: 0.1s;
    margin-left: 0.55%;
    box-shadow: 0px 15px 20px rgba(202, 202, 202, 0.2);
} */

.whiteHeadCard {
    border: 1px solid white;
    border-radius: 2px;
    background: white;
    height: 60px;
    width:100%;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    transition: 0.1s;
    margin-left: 0.55%;
    box-shadow: 0px 15px 20px rgba(202, 202, 202, 0.2);
    

}


#functionBarArea {
    border: 1px solid white;
    border-radius: 2px;
    background: white;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.1s;
    /* margin-left: 0.55%; */
    box-shadow: 0px 15px 20px rgba(202, 202, 202, 0.2);
    /* padding:20px; */
}
#searchIconAligner{
    padding:20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.searchBar {
    /* width: 100%; */
    height: 100%;
    /* border-bottom: 1px solid gray; */
    margin-right: 5%;
    display: inline-block;
    padding-top: 15px;
}
.tableEnder{
    display: flex;
    justify-content: center;
}
.tableEnderContent{
    font-weight: 600;
    color: gray;
    padding-top: 20px;;
}

/* NEW CUSTOMER PAGE CSS BELOW */

#leftBasicInfoBar{
    background: white;
    border-right: 1px solid rgb(206, 206, 206);
}
#mainContentBase{
    background: white;
}
#customerListBGArea{
    height: 65.5vh;
    width: 100%;
    background: rgba(245, 245, 245, 0.2);
    margin-top: 15px;
    margin-bottom: 15px;
    overflow-y: scroll;
}

.searchBar {
    width: 100%;
    border-bottom: 1px solid gray;
    margin-left: 10px;
    display: inline-block;
}
.searchIcon {
    display: inline-block;
}
#pageIdentifyingRow {
    height: 3vh;
    display: flex;
    justify-content: center;
}
.manageBtn Button{
    background: #00A1FF;
    transition: 0.2s;
}
.manageBtn Button:hover{
    background: rgb(43, 177, 255);
}
.activityParti{
    background: rgb(69, 69, 69) ;
    border-radius: 3px;
    text-align: center;
    font-weight: 700;
    color: white;
    width: 100%;
}
.pageIndicator{
    display: inline-block;
    margin-left: 5px;
    font-weight: 800;
    background: white;
    width: 100%;
    text-align: center;
}

.newPageInput{
    width: 90%;
}

.paymentStatus{
    width: 20%;
}
.everythingPaid{
    border-left:10px solid rgb(38, 211, 29) ;
    border-right:10px solid rgb(38, 211, 29) ;
    border-radius: 2px;
    text-align: center;
    font-weight: 700;
    /* color: white; */
    width: 60%;
}

.unfinishedPayment{
    border-left:10px solid rgb(255, 220, 25) ;
    border-right:10px solid rgb(255, 220, 25) ;
    border-radius: 2px;
    text-align: center;
    font-weight: 700;
    /* color: white; */
    width: 60%;
}
.areaIndicator{
    width:100%;
    height:100%;
    background:grey;
}
#newCustomerBtn{
    width: 100%;
    height:100%;
    background:rgb(242, 242, 242);
    border-right: 10px solid #00A1FF;
    border-left: 10px solid #00A1FF;
    background:rgb(242, 242, 242);
    color: rgb(40, 40, 40);
    font-weight: 900;
    transition: 0.2s;
}
#newCustomerBtn:hover{
    background:#00A1FF;
    border: 0px solid #00A1FF;
    color: white;
}
#unPayCustomerBtn{
    width: 100%;
    height:100%;
    background:rgb(242, 242, 242);
    border-right: 10px solid rgb(255, 220, 25);
    border-left: 10px solid rgb(255, 220, 25);
    /* background: rgb(252, 225, 70); */
    color: rgb(40, 40, 40);
    font-weight: 900;
    transition: 0.2s;
}
#unPayCustomerBtn:hover{
    background:rgb(255, 220, 25);
    border: 0px solid rgb(255, 220, 25);
    /* text-shadow: 0px 0px 5px rgb(96, 96, 96); */
    color: white;

}
#trailCustomerBtn{
    width: 100%;
    height:100%;
    background:rgb(242, 242, 242);
    border-right: 10px solid rgb(143, 143, 143);
    border-left: 10px solid rgb(143, 143, 143);
    color: rgb(40, 40, 40);
    font-weight: 900;
    transition: 0.2s;
}
#trailCustomerBtn:hover{
    background:rgb(143, 143, 143);
    border: 0px solid rgb(143, 143, 143);
    color: white;
}
#datActiveCustomerBtn{
    width: 100%;
    height:100%;
    background:rgb(242, 242, 242);
    border-right: 10px solid rgb(65, 65, 65);
    border-left: 10px solid rgb(65, 65, 65);
    color: rgb(40, 40, 40);
    font-weight: 900;
    transition: 0.2s;
}
#datActiveCustomerBtn:hover{
    background:rgb(65, 65, 65);
    border: 0px solid rgb(65, 65, 65);
    color: white;
}

.searchObjectInput{
    width:50%;
}
.searchCustomerBar{
    
    width:80%;
    border-radius: 5px;
    color: white;
    background: rgb(238, 238, 238);
    padding:0px 0px 0px 0px ;
    display: inline-block;
    
}
.customerSearchBarAlign{
    display: flex;
    align-items: center;
}

#customerSearchBtn{
    width: 18%;
    height: 50px;
    display: inline-block;
    background: rgb(238, 238, 238);
    margin-left: 2%;
    /* padding: 5px ; */
}

#hiddenBtn{
    margin: 0px;
    border: 0px solid black;
    padding: 0px 8px 0px 0px;
}

#hiddenBtn:hover{
    text-decoration:underline;
    background:white;
}
Button.customerManageNav{
    height: 50px;
    width: 100%;
    text-align: center;
    border-radius: 0px;
    box-shadow: 0px 1px 5px rgb(189, 189, 189);
}
Button.onPageBtn{
    height: 50px;
    width: 100%;
    text-align: center;
    border-bottom:1px solid rgb(43, 177, 255);
    border-radius: 0px;
    box-shadow: 0px 2px 10px rgb(43, 177, 255);
}

#customerNavPagesArea{
    height: 74.5vh;
    width: 100%;
    background: rgba(245, 245, 245, 0.2);
    margin-top: 15px;
    margin-bottom: 15px;
    overflow-y: scroll;
}

.contentBlock{
    box-shadow: 0px 0px 5px rgb(189, 189, 189);
    padding: 40px;
    margin-top: 5px;
    margin-bottom: 20px;
}
.blockTitle{
    font-weight: 800;
    height: 50px;
    /* width: 14%; */
    display: inline-block;
}
.blockTitleRowOnCustomerPackagesDisplayTitle{
    border-bottom:1px solid rgba(189, 189, 189,0.5);
    margin-bottom: 40px;
    display: flex;
    align-items: end;
}
.blockTitleRowOnCustomerPackagesDisplayTitle h3{
    margin-right: 20px;
}
.blockTitleRow{
    border-bottom:1px solid rgba(189, 189, 189,0.5);
    margin-bottom: 40px;
    display: flex;
    align-items: center;
}
.MuiSvgIcon-root.bulletBeforeData{
    height: 10px;
    color: rgb(189, 189, 189);
    margin-right: 30px;
}
.customerBasicDataName{
    display: inline-block;
    color: rgb(101, 101, 101);
    margin-left: 20px;
}
.customerBasicDataContent{
    padding-left: 100px;
    display: inline-block;
    color: rgb(101, 101, 101);
}
.customerDataContentRow{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text.customerBasicDetailsEditBtn{
    margin-left: 20px;
}
.MuiFormControl-root.MuiTextField-root.customerBasicDataContent{
    padding-left: 100px;
    display: inline-block;
    color: rgb(101, 101, 101);
}
.customerBasicDataContent .MuiInputBase-input{
    padding: 0px 0px 4px 0px;
    width: 300px;
}
.customerBasicDataContent .MuiGrid-root.MuiGrid-container.MuiGrid-justify-xs-space-around{
    
    width: 300px;
}

.customerBasicDataContent .MuiInputBase-root.MuiInput-root.MuiInput-underline.changed.MuiInputBase-formControl.MuiInput-formControl{
    color: rgba(63, 81, 181, 1);
}
.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal{
    margin: 0px;
}
.dataIcons{
    color: rgb(101, 101, 101);
}

.MuiFormControl-root.MuiTextField-root.changed div{
    color: rgba(63, 81, 181, 1)
}
.subPageBtnRow{
    width: calc( 60% - 10px);
    display: inline-block;
    
}
.searchTokenInputContainer{
    width: calc( 15% );
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 8px;
}
Button.onSubPageBtn{
    height: 50px;
    /* width: 25%; */
    text-align: center;
    /* border-bottom:1px solid rgb(43, 177, 255); */
    border-radius: 0px;
    background: rgb(0, 128, 203);
    color: white;
    /* box-shadow: 0px 2px 10px rgb(43, 177, 255); */
    margin-bottom: 8px;
}
Button.onSubPageBtn:hover{
    background: rgb(43, 177, 255);
}
Button.customerManage_PurchasePackagesSubNav{
    height: 50px;
    /* width: 25%; */
    text-align: center;
    border-radius: 0px;
    box-shadow: 0px 1px 5px rgb(189, 189, 189);
    margin-bottom: 8px;

}
.purchaseBucketArea{
    width: calc(25% - 10px);
    height: 50px;
    display: inline-block;
    margin-bottom: 8px;

}
.pName{
    width: 25%;

}
.aGroup{
    width: 15%;
}
.genre{
    width: 10%;
}
.tAmount{
    width: 15%;
}
.vPeriod{
    width: 10%;
}
.sites{
    width: 10%;
    text-align: center;
}
.price{
    width: 8%;
    text-align: center;
}
.addToBucketBtn{
    width: 7%;
}
.addToBucketBtn Button{
    float: right;
    margin-right: 10px;
    background: rgb(255, 75, 43);
    color: white;
}
.addToBucketBtn Button:hover{
    background: rgb(255, 124, 43);
}
.packagesTableHead {
    
    /* height: 60px; */
    width:99%;
    font-weight: 700;
    display: flex;
    align-items: center;
    transition: 0.3s;
    margin-left: 0.55%;

}

.contentFixSizePackagesBlock{
    box-shadow: 0px 0px 5px rgb(189, 189, 189);
    padding: 40px;
    margin-top: 5px;
    height: 73.7vh;
    
}
#contentFixSizeTableHeight {
    height: 58vh;
    overflow-y: scroll;
}

.bucketPresentationElements {
    display: inline-block;
    width: 100%;
    position: relative;
}
.itemsInBucketDisplay{
    padding: 3px 20px 3px 20px;
    background: rgb(0, 128, 203);
    color: white;
    border-radius: 5px;
    font-weight: 700;
    font-size: xx-large;
    margin-left: 10px;
    border: 0px solid black;
}
#shoppingCartIcon{
    margin-left: 10px;
    margin: 0px 5px 0px 5px;
}
#BucketSumDisplay{
    font-size: 17px;
    display: inline-block;
}
#customerBucketPreviewTitleRow{
    height: 50px;
    display: flex;
    align-items: center;
}
.previewTitleAligner{
    display: inline-block;
    width:95%;
}
#customerBucketPreviewTitle{
    font-size: 35px;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 40px; */
}

#contentFixSizeTableHeight_BucketPreviewListingArea {
    height: 75vh;
    overflow-y: scroll;
    
}
#confirmPreviewBtn{
    background: rgb(255, 75, 43);
    color: white;
    height: 50px;
    width: 20%;
    float: right;
}
#confirmPreviewRow{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
}
#previewBucketListTitleIcon{
    font-size:40px;
    margin-right: 10px;
    display: inline-block;
}
#customerInfoIcon{
    font-size:30px;

}
.customerSimInfoTitleAligner{
    display: flex;
    align-items: center;
}
.contentBlockOfClassTokenOnPreviewPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(0, 128, 203);
    border-radius: 5px;
    padding: 30px 40px 30px 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 98%;
    position: relative;
    transition:0.2s;
}
.contentBlockOfClassTokenOnPreviewPage:hover{
        box-shadow: 0px 0px 5px rgb(0, 128, 203);
}
.previewContentBlockAligner{
    width: 100%;
    display: flex;
    align-items: center;
}
.previewPackageName{
    text-align: left;
    font-size: 25px;
    font-weight: 800;
    color: white;
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;
    width: 20%;
}
.previewPackagePrice{
    font-size: 20px;
    font-weight: 800;
    color: white;
    display: inline-block;
    margin-left: 40px;
    text-align: left;
}
.previewPackageContent{
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: white;
    display: inline-block;
    margin-left: 20px;
    width: 50%;
}
#previewPackageIcon{
    font-size: 50px;
    /* margin-bottom: 10px; */
    color: white;
    display: inline-block;
}
#previewDelIcon{
    position: absolute;
    top:10px;
    right: 10px;
    font-size: 30px;
    color: white;
    transition: 0.3s;
}
#previewDelIcon:hover{
    color:rgb(255, 75, 43);
}
.inlineElement{
    display: inline-block;
}
.previewPackageContentAligner{
    width:100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
#inputForAdminChangingSellingPackageContent{
    width: 30px;
    color: white;
}
#inputForAdminChangingSellingPackageContent02{
    width: 70px;
    color: white;
}
.adminChangingSellingPackageFieldContainer p{
    color: white;
}
.adminChangingSellingPackageFieldContainer fieldset{
    border-color: white;
}
.contentBlockOfRoomRentalTokenOnPreviewPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(33, 33, 33);
    border-radius: 5px;
    padding: 30px 40px 30px 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 98%;
    position: relative;
    transition:0.2s;
}
.contentBlockOfRoomRentalTokenOnPreviewPage:hover{
    box-shadow: 0px 0px 5px rgb(24, 24, 24);
}
.usageGroupBaseArea{
    width: 100%;
    margin-bottom: 20px;
    border-bottom:1px solid rgba(101, 101, 101, 0.3);
    padding-bottom: 30px;
}
.previewBaseArea{
    width:100%;
}
.usagePackagesSumUp{
    color: rgba(24, 24, 24,0.6);
    display: inline-block;
    margin-left: 10px;
}
.usage1AreaTitle{
    font-size: 20px;
    font-weight: 700;
    padding-left: 10px;
    border-left: 5px solid rgb(0, 128, 203);
    display: inline-block;
}
.usage2AreaTitle{
    font-size: 20px;
    font-weight: 700;
    padding-left: 10px;
    border-left: 5px solid rgb(25, 189, 104);
    display: inline-block;

}
.usage3AreaTitle{
    font-size: 20px;
    font-weight: 700;
    padding-left: 10px;
    border-left: 5px solid rgb(29, 29, 29);
    display: inline-block;

}
.usage4AreaTitle{
    font-size: 20px;
    font-weight: 700;
    padding-left: 10px;
    border-left: 5px solid rgb(255, 170, 43);
    display: inline-block;

}
.contentBlockOfPrivateClassTokenOnPreviewPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(25, 189, 104);
    border-radius: 5px;
    padding: 30px 40px 30px 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 98%;
    position: relative;
    transition:0.2s;
}
.contentBlockOfPrivateClassTokenOnPreviewPage:hover{
        box-shadow: 0px 0px 5px rgb(25, 189, 104);
}
.contentBlockOfTutorRentalTokenOnPreviewPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(255, 170, 43);
    border-radius: 5px;
    padding: 30px 40px 30px 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 98%;
    position: relative;
    transition:0.2s;
}
.contentBlockOfTutorRentalTokenOnPreviewPage:hover{
        box-shadow: 0px 0px 5px rgb(255, 170, 43);
}
.contentBlockOfClassTokenOnListingPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(0, 128, 203);
    border-radius: 5px;
    padding: 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 100%;
    position: relative;
    transition:0.2s;
}
.contentBlockOfClassTokenOnListingPage:hover{
        box-shadow: 0px 0px 5px rgb(0, 128, 203);
}
.contentBlockOfPrivateClassTokenOnListingPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(25, 189, 104);
    border-radius: 5px;
    padding: 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 100%;
    position: relative;
    transition:0.2s;
}
.contentBlockOfPrivateClassTokenOnListingPage:hover{
        box-shadow: 0px 0px 5px rgb(25, 189, 104);
}
.contentBlockOfRoomRentalTokenOnListingPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(33, 33, 33);
    border-radius: 5px;
    padding: 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 100%;
    position: relative;
    transition: 0.2s;
}
.contentBlockOfRoomRentalTokenOnListingPage:hover{
    box-shadow: 0px 0px 5px rgb(24, 24, 24);
}
.contentBlockOfTutorRentalTokenOnListingPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(255, 170, 43);
    border-radius: 5px;
    padding: 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 100%;
    position: relative;
    transition:0.2s;
}
.contentBlockOfTutorRentalTokenOnListingPage:hover{
        box-shadow: 0px 0px 5px rgb(255, 170, 43);
}
.listingPackagePrice{
    font-size: 20px;
    font-weight: 800;
    color: white;
    text-align: center;
}
.listingPackageName{
    font-size: 20px;
    font-weight: 800;
    color: white;
    text-align: center;
    margin-bottom: 20px;
}
.listingPackageContent{
    font-size: 18px;
    font-weight: 500;
    color: white;
    text-align: center;
}
#listingPackageIcon{
    font-size: 100px;
    /* margin-bottom: 10px; */
    color: white;
}
#listingAddIcon{
    position: absolute;
    top:10px;
    right: 10px;
    font-size: 30px;
    color: rgb(242, 242, 242);
    transition: 0.1s;
}
#listingAddIcon:hover{
    color:rgb(255, 255, 255);
}
#listingAddIcon:active{
    color:rgb(255, 75, 43);
}

.blockTitle2{
    font-weight: 800;
    height: 50px;
    font-size: 25px;
    display: inline-block;
}
.blockTitle2Underline{
    background: rgba(202, 202, 202, 0.5);
    height: 6px;
    width: 40px;
    margin-bottom: 20px;
}
.purchaseSumUpItemName{
    font-weight: 800;
    display: inline-block;
}
.purchaseSumUpItemPrice{
    display: inline-block;
    font-weight: 800;
    color: rgb(0, 128, 203);
}
.purchaseSumUpItem{
    display: flex;
    justify-content: space-between;
}
.contentBlock2{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    padding: 40px;
    margin-top: 5px;
    margin-left: 40px;
    border: 1px solid rgba(101, 101, 101, 0.3);
}
.purchaseUnderline{
    background: rgba(202, 202, 202, 0.5);
    height: 6px;
    width: 40px;
    margin: 20px 0px 20px 0px;
}
#confirmPaymentBtn{
    width: 100%;
    height: 60px;
    border: 2px solid rgb(0, 128, 203);
    color: rgb(0, 128, 203);
    border-radius: 0px;
    margin-top: 30px;
    transition: 0.3s;
}
#confirmPaymentBtn:hover{
    border: 2px solid rgb(211, 97, 77);
    color: rgb(211, 97, 77);
    background: white;
}
#confirmPaymentBtnDisabled{
    width: 100%;
    height: 60px;
    border: 2px solid rgb(97, 97, 97);
    color: rgb(97, 97, 97);
    border-radius: 0px;
    margin-top: 30px;
    transition: 0.3s;
}
#confirmPaymentBtnDisabled:hover{
    border: 2px solid rgb(211, 97, 77);
    color: rgb(211, 97, 77);
    background: white;
}
.paymentMethodBlock3{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    height: 80px;
    border: 1px solid rgba(101, 101, 101, 0.1);
    display: inline-block;
    transition: 0.3s;
}
.paymentMethodBlock3:hover{
    border:1px solid rgba(101, 101, 101, 0.4);
}
.paymentMethodContent{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.paymentName{
    font-size: 18px;
    font-weight: 500;
    color: rgb(119, 118, 118);
    margin-left: 10px;
    display: inline-block;

}
#paymentIcon{
    display: inline-block;
    color: rgb(119, 118, 118);
}
.paymentMethodChosenBlock3{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    height: 80px;
    display: inline-block;
    transition: 0.3s;
    background: rgb(0, 128, 203);
}
#paymentChosenIcon{
    display: inline-block;
    color: rgb(255, 255, 255);
}
.paymentChosenName{
    font-size: 18px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    margin-left: 10px;
    display: inline-block;
}
.EPSLogo{
    font-weight: 900;
    padding: 0px 5px 0px 5px;
    border: 2px solid rgb(119, 118, 118);
    color: rgb(119, 118, 118);
    border-radius: 5px;
}
.EPSChosenLogo{
    border: 2px solid rgb(255, 255, 255);
    color: rgb(255, 255, 255);
}
.purchaseSumUpItem2{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    
}
.purchaseSumUpItemPrice2{
    display: inline-block;
    font-weight: 800;
    color: rgb(203, 14, 0);
}
.purchaseSumUpItemPrice3{
    display: inline-block;
    font-weight: 800;
    color: rgb(79, 79, 79);
}
.discountListingArea{
    width:100%;
    height: 150px;
    border: 1px solid rgba(101, 101, 101, 0.1);
    margin-bottom: 10px;
    
}
.discountListingAreaFillUp{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.noDiscountIndicator{
    color: rgba(101, 101, 101, 0.8);
}
.discountListingRow{
    border: 1px solid rgba(101, 101, 101, 0.3);
    width: 100%;
    height:55px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.disCountListingAreaBG{
    padding: 0px 5px 0px 5px;
    height:100%;
    overflow-y: scroll;
}
.discountName{
    display: inline-block;
    width: 30%;
    text-align: center;
    font-weight: 700;
    color: rgba(101, 101, 101, 0.9);
}
.chosenDiscountListingRow{
    width: 100%;
    height:55px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(0, 128, 203);
}

.chosenDiscountName{
    display: inline-block;
    width: 30%;
    text-align: center;
    font-weight: 700;
    color: rgb(255, 255, 255);
}
.paymentAmountBlock{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    height: 50px;
    /* border: 1px solid rgba(101, 101, 101, 0.1); */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.3s;
    margin-top: 20px;
}


.paymentAmountBlock .MuiFormControl-root.MuiTextField-root{
    width: 100px;
    display: inline-block;
    margin-left: 20px;
    
}

.contentBlockOfExpiredTokenOfCustomerOnListingPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgba(108, 108, 108,0.6);
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 24%;
    position: relative;
    transition:0.2s;
}
.contentBlockOfClassTokenOfCustomerOnListingPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(0, 128, 203);
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 24%;
    position: relative;
    transition:0.2s;
}
.contentBlockOfClassTokenOfCustomerOnListingPage:hover{
        box-shadow: 0px 0px 5px rgb(0, 128, 203);
}
.contentBlockOfPrivateClassTokenOfCustomerOnListingPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(25, 189, 104);
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 24%;
    position: relative;
    transition:0.2s;
}
.contentBlockOfPrivateClassTokenOfCustomerOnListingPage:hover{
        box-shadow: 0px 0px 5px rgb(25, 189, 104);
}
.contentBlockOfRoomRentalTokenOfCustomerOnListingPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(33, 33, 33);
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 24%;
    position: relative;
    transition: 0.2s;
}
.contentBlockOfRoomRentalTokenOfCustomerOnListingPage:hover{
    box-shadow: 0px 0px 5px rgb(24, 24, 24);
}
.contentBlockOfTutorRentalTokenOfCustomerOnListingPage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(255, 170, 43);
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 24%;
    position: relative;
    transition:0.2s;
}
.contentBlockOfTutorRentalTokenOfCustomerOnListingPage:hover{
        box-shadow: 0px 0px 5px rgb(255, 170, 43);
}

#listingPackageOfCustomerIcon{
    font-size: 80px;
    /* margin-bottom: 10px; */
    color: white;
}

.listingPackageOfCustomerName{
    font-size: 18px;
    font-weight: 800;
    color: white;
    text-align: center;
    margin-bottom: 10px;
    white-space: pre-wrap;
}

.listingPackageOfCustomerContent{
    font-size: 15px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    text-align: center;
}
.fixedHeightTermsListingBox{
    height: 57vh;
    width: 100%;
    /* border: 1px solid rgba(101, 101, 101, 0.3); */
    overflow-y: scroll;
}
.noneDisplayBox{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(101, 101, 101);
}
#clickToChooseNewTermsBtn{
    width:100%;
    background: rgb(0, 128, 203);
    color: white;
    font-weight: 700;
    margin-top: 10px;
}
#clickToChooseNewTermsBtn2{
    width:18%;
    background: rgb(0, 128, 203);
    color: white;
    font-weight: 700;
    margin-top: 30px;
}
#termRowSelectForm{
    width: 95%;
}
.termRow{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
#delTermsBtn{
    margin-left: 10px;
    color: rgb(24, 24, 24);
    transition: 0.2s;
}
#delTermsBtn:hover {
color: rgb(255, 75, 43);
}
#confirmTermsBtn{
    border:2px solid rgb(0, 128, 203);
    border-radius: 0px;
    color: rgb(0, 128, 203);
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    height: 100px;
    transition: 0.2s;
}
#confirmTermsBtn:hover{
    color: rgb(255, 75, 43);
    border: 2px solid rgb(255, 75, 43);
    background: white;
}
.contentBlock3{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    /* padding: 40px; */
    margin-top: 5px;
    margin-left: 30px;
}
.fixedHeightInvoicePreviewBox{
    position: relative;
  width: 70%;  
  /* height: 29.7cm;  */
  margin: 0 auto; 
  padding: 20px;
  color: #001028;
  background: #FFFFFF; 
  /* font-family: Arial, sans-serif; 
  font-size: 12px; 
  font-family: Arial; */
  /* box-shadow: 0px 0px 5px black; */
}
#confirmPurchaseBtn{
    border:2px solid rgb(0, 128, 203);
    border-radius: 0px;
    color: rgb(0, 128, 203);
    font-weight: 600;
    font-size: 18px;
    width: 21cm;
    height: 100px;
    transition: 0.2s;
    margin-top: 20px;
}
#confirmPurchaseBtn:hover{
    border: 2px solid rgb(211, 97, 77);
    color: rgb(211, 97, 77);
    background: white;
}
.logoOnInvoice{
    height: 350px;
    position: absolute;
    top: -80px;
}
.invoiceHeader{
    height: 33%;
    background: rgb(255, 249, 243);
}
.invoiceBody{
    height: 67%;
    border:1px solid rgb(29, 29, 29);

}
#searchOptionInCustomerMainListingPage{
    width:30%;
}
#searchingInputInCustomerMainListingPage{
    width: 40%;
    margin-left: 10px;
}
#searchBtnContainerInCustomerMainListingPage{
    width: 27%;
    height: 48px;
    display: inline-block;
}
.noOtherContactDetails{
    width: 100%;
    display: flex;
    justify-content: center;
    color: rgb(101, 101, 101);
}
.customerOtherDataContent{
    padding-left: 20px;
    display: inline-block;
    color: rgb(101, 101, 101);
}
.indicator{
    display: inline-block;
}
.pageIndicatorContainer{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.noneCustomerPreviewContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fixedHeightPreviewCustomerBar{
    width: 100%;
    height: 81vh;
    overflow: hidden;
}
.noneDisplay{
    color: rgb(101, 101, 101);
}
.customerPreviewContainer{
    width: 100%;
    height: 100%;
    padding: 20px;
}
#customerPreviewNoneIconSize{
    font-size: 250px;
    color: rgb(167, 167, 167);
}
.customerProfilePicRow{
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}
.customerInfoRow{
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin-bottom: 40px;
    margin-left: 15px;
}
.customerPreviewDetails{
    margin-left: 20px;
    color: rgb(21, 21, 21);
    font-weight: 500;
    display: inline-block;
}
.customerGuardianDataContent{
    padding-left: 50px;
    display: inline-block;
    color: rgb(101, 101, 101);
}
.customerGuardianDataName{
    display: inline-block;
    color: rgb(101, 101, 101);
    margin-left: 20px;
}
.customerGroupingIndicator{
    background: rgb(69, 69, 69);
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    height: 40px;
}
#editCustomerPackageBtn{
    background: white;
}
.editPackageBtnContainer{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.editPackageInputField{
    background: white;
    border-radius: 5px;
    margin-top: 10px;
}
#discountUsingCountOnPurchasePage{
    display: inline-block;
    color: white;
}
#discountUsingCountOnPurchasePage-label{
    color: white;
}
.discountUsingCounterContainer{
    width: 100px;
    display: inline-block;
    color: rgba(101, 101, 101, 0.9);

}
#otherCustomerDataOperationBtn{
    height: 50px;
    width: 12%;
    color: white;
    background: rgb(0, 128, 203);
    transition: 0.2s;
    margin: 0.25%;
}
#otherCustomerDataOperationBtn:hover{
    background: rgb(43, 177, 255);
}
.sendEmailTriggerContainer svg{
    color: rgb(0, 128, 203);
}
.sendEmailTriggerContainer{
    margin-top: 20px;
    width: 21cm;
}
.listingPackageContentTitle{
    display: inline-block;
}
.listingPackageContentDetails{
    display: inline-block;
    width: 60%;
    background: rgba(255, 255, 255, 0.2);

}
.listingPackageContentRow{
    display: flex;
    justify-content: center;
    color: white;
    font-size: 18px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background: rgba(0, 0, 0, 0.2); */
}
.packageListingContentAligner{
    display: flex;
    justify-content: center;
}
.listingPackageContentGenreDetails{
    display: inline-block;
    width: 60%;
    height: 110px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: rgba(255, 255, 255, 0.2);
    /* border-radius: 5px; */
}

.listingPackageNameContainer{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}
.secondRowCustomerDetailsSideCard{
    height: calc(33.3% - 25px);
    overflow-y: scroll;
}
.referralDetails{
    font-weight: 600;
    margin-bottom: 10px;
}
.productContentInlineDisplayOnBucketPreview{
    font-size: 15px;
    font-weight: 300;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid white;
    padding: 5px;
    border-radius: 5px;
    color: white;
}
.inventoryProductAttributeOnBucketPreview{
        margin-top: 10px;
        width: 100%;
        margin-right: 20px;
        height: 100%;
}
.navBlinder01{
    position: absolute;
    width: 100vw;
    height: 60px;
    z-index: 99999;
    top: -90px;
    left: -30px;
    background: rgba(255, 255, 255, 0.9);
    display: flex;

}
.navBlinderContentContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: 700;
}
#navBlinderIcon{
    width: 60px;
    height: 40px;
    color: red;
}