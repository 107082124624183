.appHeader {
    height:60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0px 0px;
}

#saveBtn {
    position: absolute;
    right:12px;
    width: 35px ;
    height:35px;
     color: rgba(255, 255, 255, 0.7);
     transition: 0.3s;
}

#saveBtn:hover {
    color: rgba(255, 255, 255, 1);
}
#backBtn {
    position: absolute;
    left:15px;
    width: 32px ;
    height:32px;
     color: rgba(255, 255, 255, 0.7);
     transition: 0.3s;
}
#backBtn:hover {
    color: rgba(255, 255, 255, 1);
}
.sectionAligner{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.sectionHeader {
    font-size: 20px;
    color: rgba(75, 74, 74, 0.5);
}
.leftDivider{
    border-left: 1px solid rgba(126, 125, 125, 0.1);
}
.tutorPicOutLine{
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 1px solid rgba(126, 125, 125, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.tutorPic{
    height: 109px;
    width: 109px;
    border-radius: 50%;
    background: rgba(75, 74, 74, 0.5);
}
.sectionSubHeading {
    font-size: 18px;
    color: rgba(75, 74, 74,1);
    font-weight: 600;
}
.dataCounting {
    font-size: 25px;
    display: inline-block;
    font-weight: 600;
}
.content {
    font-size: 12px;
    font-weight: 300;
    color: rgba(75, 74, 74, 0.6);
}
.sectionAligner2{
   display: flex;
   justify-content: center;
}
#students {
    border-left: 1px solid rgba(126, 125, 125, 0.1);
    padding-right: 3px;
}
.blueButton{
    background: royalblue;
}
/* #englishName{
    width: 30%;
    margin-right: 20px;
} */
.MuiFormControl-root.MuiTextField-root.Inputs {
    width: 80%;
    
}
#ageInput {
    width: 80%;
}
.sectionAligner Button {
    width: 90%;
}
.MuiFormControl-root{
    width: 100%;
}
.highlightedSection{
    background: rgba(126, 125, 125, 0.1);
    padding: 5px;
    
}
.highlightedSectionChanged{
    background: rgba(63, 81, 181, 0.1);
    padding: 5px;
}

/* #lastRoll1{
    padding-right: 0px;
}
#lastRoll2{
    padding-left: 0px;
} */
.MuiFormGroup-root.MuiFormGroup-row{
    height:150px;
    overflow-x: scroll;
}
.issueDate{
    width: 80%;
}
#formLastRowAlign{
    margin-top: 7px;
}
.topDivider{
    height: 80px;
    border-top: 1px solid rgba(126, 125, 125, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.MuiFormControl-root.MuiTextField-root.Inputs.changed div{
    color: rgba(63, 81, 181, 1)
}
/* .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl div {
    color: rgba(63, 81, 181, 1)
} */
.MuiFormControl-root.selectInputs.changed div {
    color: rgba(63, 81, 181, 1)

}
.MuiFormControlLabel-root.changed {
    color: rgba(63, 81, 181, 1)

}
.baseAreaOfAddNewCustomer{
    position: fixed;
    top: 16%;
    right: 9%;
    margin: auto;
    background: white;
    width: 60%;
    height: 80%;
    z-index: 4;
    border-radius: 5px;
    box-shadow: 0px 0px 15px black;
    padding: 40px;
}
#clickToAddNewCustomerBtn{
    width: 30%;
    background: rgb(0, 128, 203);
    color: white;
    font-weight: 700;
    margin-top: 10px;
}
.newCustomerInputFormContainer{
    width: 100%;
    height: 95%;
    overflow-y: scroll;
}