.dashBoardCard01 {
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px; */
    /* margin: 20px; */
     height: 100%;
    padding: 30px; 
    position: relative;
    display: flex;
    flex-direction: column;
    /* min-width: 0; */
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid #dee2e6;
    border-radius: .25rem;
    box-shadow: 0 0 13px 0 rgb(236 236 241 / 44%);
}
.dashBoardCard {
    box-shadow: 0 0 13px 0 rgb(236 236 241 / 44%);
    border-radius: .25rem;
    /* margin: 20px; */
    height: 100%;
    padding: 20px;
    background-color: #fff;
}
.dashBoardRow1{
    /* height: calc(40vh - 20px); */
    /* height: 400px; */
    margin-bottom: 1.5rem;
}
.dashBoardRow2{
    /* height: 15vh; */
    height: 140px;
    margin-bottom: 20px;
    /* margin-top: 20px; */
}
.dashBoardRow3{
    /* height: calc(35vh - 20px); */
}
.dashBoardRow2 .dashBoardCard{
    background:  rgba(1, 81, 151,0.9);

}
.marginLeft{
    margin-left: 3vw;
}
.height80{
    height: 80%;
}
.revenueDashboardRight{
    height: 100%;
    width: 30%;
    /* border: black 1px solid; */
    display: inline-block;
}
.revenueDashBoardLeft{
    height: 100%;
    width: 70%;
    /* border: 1px solid black; */
    display: inline-block;

}
.revenueDashBoardBottom{
    height: 20%;
    width: 100%;
    display: inline-block;
    border-top: 1px solid rgba(100, 100, 100, 0.1);
    margin-top: 10px;
}
.boardSectionTitle{
    font-weight: 700;
    /* border-bottom: 1px solid black; */
    display: inline-block;
}
.revenueDashBoardAlign{
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 15px;
}
.dashNumberExplain{
    font-weight: 400;
    font-size: 17px;
    color: rgba(100, 100, 100, 1);
}
.dashNumberExplain02{
    font-weight: 400;
    font-size: 15px;
    color: rgba(100, 100, 100, 1);
    margin-bottom: -5px;
}
.dashNumbers01{
    margin-top: 15px ;
    font-weight: 700;
    font-size: 40px;
    margin-bottom: -5px;
}
.dashNumbers02{
    font-weight: 600;
    font-size: 25px;
}
.dashNumberFont{
    font-family: 'Lato';
    src: local('Lato-Regular'),url(../fonts/Lato-Regular.ttf);
}
.paymentTypeSummaryArea{
    width: 100%;
    border-right: 1px solid rgba(100, 100, 100, 0.1);
    /* height: 100%; */
    display: inline-block;
    padding-top: 5px;
}
.paymentTypeSummaryAreaRight{
    width: 100%;
    /* border-right: 1px solid rgba(100, 100, 100, 0.1); */
    /* height: 100%; */
    display: inline-block;
    padding-top: 5px;
}
#paymentMethodIconOnDash{
    font-size: 40px;
}
.paymentMethodAvatar{
    margin-right: 10px;
}
#cashAvatarOnDash{
    background: rgb(255, 196, 0);
}
#cardAvatarOnDash{
    background: green;
}
#bankAvatarOnDash{
    background: rgb(0, 128, 203);
}
#EPSAvatarOnDash{
    background: rgb(134, 209, 250);
}
#depositAvatarOnDash{
    background: rgb(236, 115, 255);
}
#otherAvatarOnDash{
    background: rgb(255, 145, 49);
}
.methodColorIndicator{
    min-height: 50px;
    width: 10px;
    margin-right: 30px;
}


.cardTitleRowOnDashboard{
    display: flex;
}
.lineIndicatorContainer{
    display:inline-block;
    margin-left: 20px;
}
.indicatorAligner{
    display: flex;

}
.lineIndicator{
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.lineSample01{
    background:rgb(0, 98, 204);
    width: 25px;
    height: 5px;
    display: block;
    margin-right: 10px;
    border-radius: 0.25rem;
}
.lineSample02{
    background:#02a499;
    width: 25px;
    height: 5px;
    display: block;
    margin-right: 10px;
    border-radius: 0.25rem;
}
.lineSample03{
    background:rgb(0, 128, 203);
    width: 25px;
    height: 5px;
    display: block;
    margin-right: 10px;
    border-radius: 0.25rem;
}
.lineSample04{
    background:rgb(255, 198, 101);
    width: 25px;
    height: 5px;
    display: block;
    margin-right: 10px;
    border-radius: 0.25rem;
}
.lineSample05{
    background:rgb(45, 45, 45);
    width: 25px;
    height: 5px;
    display: block;
    margin-right: 10px;
    border-radius: 0.25rem;
}
#SAChartAligner{
    /* height: 100px; */
}
.targetPackageRowOnSAGraph{
    padding-top: 20px;
    padding-bottom: 20px;
    /* border: 1px solid grey; */
    border-radius: 0.25rem;
    margin-bottom: 20px;
    font-weight: 700;
    color: white;
    display: flex;
    align-items: center;
    /* font-size: 15px; */
}
.areaScroll{
    overflow: scroll;
    height: calc(100% - 40px);
}
.groupClassPackageBGColor{
background: rgb(0, 128, 203);
}
.privateClassPackageBGColor{
    background: rgb(25, 189, 104);
}
.roomRentalPackageBGColor{
    background: rgb(33, 33, 33);
}
.tutorRentalPackageBGColor{
    background: rgb(255, 170, 43);
}
#packageIconOnSAGraph{
    font-size: 40px;
    color: white;
}
.packageNameIndicatorOnSAGraph{
    font-size: 1rem;
}
.packageRankNumber{
    font-weight: 700;
    font-size: 2rem;
    margin-left: 20px;
    margin-right: 10px;
}
#keyInfoIcon{
    color: white;
    font-size: 50px;
}
.floatLeftIconContainer{
    padding: 25px;
    background: rgba(255,255,255,0.1);
    
}
#incrementIndicator{
    font-size: 20px;
    margin-top: 5px;
    margin-left: 5px;
}
.dashBoardArea{
    height: 100%;
}
#sportPopularityDonutChart{
    height:250px
}
#sportPopularityDonutChart g{
    height: 100%;
}
.sportPopularityChartColor-a{
    background: rgb(0, 98, 204)
}
.sportPopularityChartColor-b{
    background: #02a499;
}
.sportPopularityChartColor-c{
background: #f8b425;
}
.sportPopularityChartColor-d{
background: #343a40;
}
.sportPopularityChartColor-e{
background: #38a4f8;
}
.sportPopularityChartColor-f{
background: #ec4561;
}
.badge.sportPopularityChartColor-g{
    background: #F8F9FC;
    color: rgb(33, 33, 33);
    }
.monitorCustomerRow{
    /* border-top: 1px solid rgb(53, 53, 53); */
    border-bottom: 1px solid rgba(53, 53, 53,0.1);
    padding: 20px 10px;
    transition: 0.2s;
    display: flex;
    align-items: center;
    font-weight: 700;
}
.monitorCustomerRow:hover{
    background: #f7f7f7;
}
.monitorCustomerTitleRow{
    border-bottom: 1px solid rgba(53, 53, 53,0.1);
    padding: 10px;
    display: flex;
    align-items: center;
    font-weight: 700;
}
.monitorCustomerTable{
    height: calc(100% - 80px);
    overflow: scroll;
    max-height: 600px;
}
#momitorCustomerIcon{
    color: rgb(255, 198, 101);
    font-size: 40px;
    margin-right: 10px;
}
#momitorCustomerIcon02{
    color: rgb(255, 142, 101);
    font-size: 40px;
    margin-right: 10px;
}
.card-title-of-monitor-customers{
    font-size: 16px;
    height: 50px;
}
.underline{
    text-decoration: underline;}
.scrollArea{
    overflow: scroll;
    height: calc(100% - 35px);
    max-height: 640px;
}
.barChartIndicatorContainer{
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    padding-top: 10px;
}
.barChartIndicatorTitle{
}
.barChartIndicatorData{
    font-size: 40px;

}
.borderRightOfBarChartIndicator{
    border-right: 1px solid rgba(53, 53, 53,0.1);

}
.barChartIndicatorContainer:hover{
    background: #f7f7f7;
}

.secondTierDataBox{
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
}
.secondTierDataBox:hover{
    background: #f7f7f7;
}