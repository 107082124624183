#specificPurchaseDetailsBox{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
    padding: 30px;
    background: #F6F6F6;
}
#closeIconOnSpecificPurchaseBox{
    /* position: absolute; */
    right: 20px;
    font-size: 30px
}
.blockTitleOfSpecificPurchase{
    font-weight: 800;
    height: 50px;
    margin-top: 15px;
    /* width: 14%; */
    display: inline-block;
}
#functionBtn{
    margin-left: 20px;
    /* background: rgb(0, 128, 203); */
    /* color: white; */
    font-weight: 700;
    height: 50px;
    padding:10px;
}
#functionBtn02{
    margin-left: 20px;
    /* background: rgb(0, 128, 203); */
    /* color: white; */
    font-weight: 700;
    height: 50px;
    padding:10px;
    z-index: 999;
}
.blockTitleRowOfSpecificPurchase{
    border-bottom: 1px solid rgba(189, 189, 189,0.5);
}
.purchaseDetailsPageButtonIndicator01{
    color: rgb(0, 128, 203);
    font-size: 20px;
    /* box-shadow: 0px 0px 5px rgb(0, 128, 203); */
    /* background: #FFFFFF; */
    height:50px
}
.purchaseDetailsPageButtonIndicator02{
    color: rgba(189, 189, 189,1);
    font-size: 20px;
    /* box-shadow: 0px 0px 5px rgb(0, 128, 203); */
    /* background: #FFFFFF; */
    height:50px

}
#btnIconOfSpecificPurchaseBox{
    width: 30px;
    height:30px
}
.buttonContent{
    display: inline-block;
    font-weight: 700;
    font-size: 20px;
}
.buttonContentContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.purchaseSummaryBlock{
    border: 0px solid #000000;
    border-radius: 5px;
    height: 80vh;
    margin-top: 20px;
    box-shadow: 0px 0px 5px rgb(0, 0, 0);
    padding: 40px;
    overflow: scroll;
}

.purchaseDetailsBlock{
    height: 80vh;
    margin-top: 20px;
    padding-left: 20px;
    overflow: scroll;
}
.paidIndicator{
    background: rgb(0, 128, 203);
    border-radius: 50%;
    padding: 8px;
    display: inline-block;
    margin-right: 20px;
}
.unpaidIndicator{
    background: rgb(245, 215, 42);
    border-radius: 50%;
    padding: 8px;
    display: inline-block;
    margin-right: 20px;
}
.voidIndicator{
    background: rgb(245, 42, 42);
    border-radius: 50%;
    padding: 8px;
    display: inline-block;
    margin-right: 20px;
}
.amountPayable{
    font-size: 3.5vw;
    font-weight:700;
    line-height: 60px;
    margin-bottom: 40px;
    width: 100%;
    overflow-x: hidden;
}
.sumUpDataTitle{
    font-size: 20px;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 5px;
}
.sumUpDataContent{
    font-size: 20px;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 5px;
}
.rightAligner{
    display: flex;
    justify-content: flex-end;
}
.breaker{
    margin-top: 8px;
    margin-bottom: 15px;
    background: #000000;
    height: 5px
}
.dottedBreaker{
    border-top: 5px dotted #000000;
    margin-top: 9px;
    margin-bottom: 14px;
}
.subBlockTitle{
    font-weight: 600;
    height: 30px;
    display: inline-block;
    /* border-bottom: 3px solid #000000; */
}
.subBlockBG{
    background: #FFF;
    border-radius: 5px;
    /* margin-top: 10px; */
    margin-bottom: 20px;
    padding: 15px 15px;
}
.itemContentRow{
    border-radius: 5px;
    border: 1px solid rgba(189, 189, 189,0.5);
    padding: 15px;
    margin: 0px 0px;
    box-shadow: 0px 0px 5px rgba(189, 189, 189,0.5);
}
.inline{
    display: inline-block;
}
.contentRow01{
    display: flex;
    justify-content: space-evenly;
    font-weight: 700;
    margin-bottom: 10px;
}
.contentMain{
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 20px;
    border-bottom: 5px solid #000000;
    display: inline-block;
}
.contentMainPrice{
    font-weight: 700;
    font-size: 1.2vw;
    margin-bottom: 10px;
    background: rgb(0, 128, 203);
    color: #FFFFFF;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height:55px;
}
.paymentContentRow{
    /* border-radius: 5px; */
    border: 1px solid rgba(189, 189, 189,0.5);
    border-bottom: 0px solid rgb(11, 173, 106);
    padding: 15px;
    margin: 0px 0px;
    box-shadow: 0px 0px 5px rgba(189, 189, 189,0.5);
}
.paymentBaseLine{
    height: 5px;
    margin: 0px 0px;
}
.paymentColor01{
    background:rgb(255, 196, 0);
}
.paymentColor02{
    background:rgb(11, 173, 106);
}
.paymentColor03{
    background: rgb(134, 209, 250);
}
.paymentColor04{
    background:rgb(0, 128, 203);
}
.paymentColor05{
    background:rgb(236, 115, 255);
}
.paymentColor06{
    background:rgb(255, 145, 49);
}
.discountColor{
    background: rgb(109, 109, 109);
}
.cxlColor{
    background: rgb(245, 42, 42);
}
.paymentRow{
    margin: 20px 0px;
}
.contentRemarksRow{
    font-weight: 700;
    margin: 10px 0px;
    padding: 20px 20px;
    background: rgba(189, 189, 189,0.2);
}
#voidWarningIcon{
    font-size: 30px;
    color: rgb(245, 42, 42);
    margin-right: 10px;
}
.voidIndicatorContent{
    font-weight: 700;
    font-size: 25px;
}
.voidAligner{
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@font-face {
    font-family: 'D-DINCondensed';
    src:local('D-DINCondensed'),url(../../fonts/D-DINCondensed.ttf);
}
@font-face {
    font-family: 'CHI-FONT';
    src:local('CHI-FONT'),url(../../fonts/TaipeiSansTCBeta-Regular.ttf);
}
@media print {
    /* html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    } */
    #pageContainer{
        page-break-after: always;
        height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
#receiptBase{
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: center;
    background: white;
    font-family: "D-DINCondensed", "CHI-FONT";
}
.printableReceipt{
    width: 100%;
    height: 100vh;
    z-index: 6;
    /* 6 */
    position: fixed;
    top: 0%;
    left: 0%;
    overflow-y: scroll;
    background: white;
}
.receiptMainPart{
    /* white-space: nowrap; */
    /* display: flex; */
}
#pageContainer{
    padding-top: 20px;
    width: 27cm;
    height: auto;
    min-height: 41cm;
    display: flex;
    justify-content: center;
}
.attnColBG{
    /* height: 100%; */
    width: 100%;
    /* background: #ffffff; */
    background: #f4f4f4;
    padding-top: 300px;
    padding-bottom: 40px;
    padding-left: 40px;
    color: rgb(0, 0, 0);
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border-right: 10px solid #000000; */
}
.attnSectionTitle{
    font-weight: 700;
    /* margin-bottom: 20px; */
}
.attnSectionTitle02{
    font-weight: 700;
    margin-bottom: 20px;
}
.attnSectionContent{
    margin-bottom: 60px;
}
.mainPadding{
    padding: 80px 0px 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border-left: 10px solid black; */
}
.receiptTitle{
    font-size: 56px;
    font-weight: 900;
    color: rgb(0, 0, 0);
    display: inline-block;
}
.receiptTitleContainer{
    margin-bottom: 80px;
    display: inline-block;
}
.purchaseIDUnderline{
    border-bottom: 10px solid rgb(255, 196, 0);
    width: 50px;
    height: 100%;
    display: inline-block;
}
.purchaseID{
    display: inline-block;
    font-size: 36px;
    margin-left: 20px;
}
.rightAlignerOfReceipt01{
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}
.lineDivider{
    border-bottom: 5px solid rgb(255, 196, 0);
    width: 100%;
}
.tableHeader{
    font-size: 24px;
    font-weight: 900;
    display: inline-block;
}
.tableHeader02{
    font-size: 24px;
    font-weight: 900;
    display: inline-block;
    margin-right: 40px;
}
.itemDetailsContainer{
    display: inline-block;
    width: auto;
    min-width: 60%;
}
.spaceBetweenContainer{
    display: flex;
    justify-content: space-between;
}
.receiptMainContentArea{
    height: auto;
    min-height: 450px;
}
.receiptMainContentArea{
    margin-top: 20px;
}
.itemGroup{
    margin-bottom: 20px;
    border-bottom: 3px solid rgb(0, 0, 0);
    padding-bottom: 10px;
}
.itemGroup02{
    margin-bottom: 20px;
    /* border-bottom: 3px solid rgb(0, 0, 0); */
    padding-bottom: 10px;
}
.itemName{
    font-weight: 700;
    font-size: 22px;
}
.itemDetails{
    font-weight: 500;
    font-size: 18px;
}
.itemDetails02{
    font-weight: 500;
    font-size: 15px;
    margin-bottom:7px;
}
.monetaryDisplay{
    font-size: 2.5vw;
    font-weight: 700;
}
.monetaryIndicator{
    font-size: 20px;
    font-weight: 500;
    color: rgb(94, 94, 94);
}
.discountSectionContainer{
    margin-top: 80px;
}
.contentTableHead{
    margin-bottom: 20px;
}
.contentTableHead02{
    margin-bottom: 20px;
    margin-top: 30px;
}
.itemDetailsInline{
    font-weight: 500;
    font-size: 18px;
    width: auto;
    min-width: 49%;
    display: inline-block;
}
.logo01{
    background: url("../../Logos/site02Logo.png");
    background-position: center;
    height: 100px;
    width: 90%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 20px;
}
.logo02{
    background: url("../../Logos/site01Logo.png");
    background-position: center;
    height: 120px;
    width: 90%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-bottom: 0px;
    /* margin-top: 10px; */
}
.footer{
    /* border-top: 3px solid #3c3c3c; */
    margin-top: 60px;
    padding-bottom: 20px;
}
.studioName{
    font-weight: 700;
    font-size: 20px;
}
.footerContentContainer{
    /* width: 33%; */
    display: inline-block;
    border-right: 1px solid black;
    padding-right: 40px;
}
.footerContentContainer02{
    /* width: 33%; */
    display: inline-block;
    padding-left: 40px;
    padding-bottom: 10px;

    /* border-right: 3px solid black; */
}
.tableHeaderBreakUnderline{
    border-bottom: 5px solid black;
    /* border-top: 5px solid black; */
}