.subPageBtnRowOnPackageMan{
    width: 80%;
    display: inline-block;
    margin-left: 10px;
}
.fixedHeightPackageListingAreaOnPackageMan{
    height: 74.5vh;
    overflow-y: scroll;
}
.editDelButtonListingRow{
    display: flex;
    justify-content: center;
}
#clickToEditPackageBtn{
    width: 100%;
    color: rgb(0, 128, 203);
    background: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
#addPackageTriggerBtn{
    background: rgb(25, 189, 104);
    color: white;
    height: 50px;
    /* width: 100%; */
    transition: 0.2s;
    /* margin: 10px; */
}
#massEditGenreBtn{
    background: rgb(255, 166, 0);
    color: white;
    height: 50px;
    transition: 0.2s;

}
#addPackageTriggerBtn:hover{
    background: rgb(30, 218, 121);
}
#massEditGenreBtn:hover{
    background: rgb(255, 98, 0);

}
.newPackageTitleRow{
    height: 59px;
    display: flex;
    align-items: center;
    font-size: 23px;
    font-weight: 500;
    border-bottom: 1px solid rgb(201, 200, 200);
}
.stepsIndicatorRow{
    margin-top: 20px;
}
#stepIconColor svg.MuiStepIcon-active{
    color: rgb(0, 128, 203);
}
#stepIconColor svg.MuiStepIcon-completed{
    color: rgb(0, 128, 203);
}
.fixedHeightWindowOnNewPackageComponent{
    height: 60vh;
    width: 100%;
    overflow-y: scroll;
    /* display: flex;
    align-items: center; */
}
.mainInputRow{
    width: 100%;
    /* padding-left: 100px;
    padding-right: 100px; */
}
.usageOptionContainer{
    width: 100%;
    height: 100%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tokenUsage{
    color: white;
    /* margin-top: 20px; */
    font-size: 25px;
    font-weight: 500;
    text-align: center;
}
.usageBlockNotChosen{
    background: rgb(160, 160, 160);
    border-radius: 5px;
    padding: 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 100%;
    position: relative;
    transition: 0.2s;
}
.usageBlockNotChosen:hover{
    box-shadow: 0px 0px 5px rgb(60, 60, 60);
}
#stepButton{
    width: 10%;
    background: rgb(0, 128, 203);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
#stepButtonNoShow{
    width: 10%;
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
.modifyStepsRow{
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    justify-content: space-between;
}
.stepButtonDisabled{
    width: 10%;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
.tokenNameLabel{
    font-weight: 400;
    display: inline-block;
    margin-bottom: 20px;
}
.packageNameInput{
    display: inline-block;
    /* width: 50%; */
}
.packageTokenBasedPackageInput{
    display: inline-block;
    width: 50%;
}
.packageTokenBasedPackageInput .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    color: white;
}
.packageTokenBasedPackageInput .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: rgba(0, 128, 203,0.7);
}
.packageTokenBasedPackageInput .MuiSwitch-colorPrimary.Mui-checked {
    color: rgb(0, 128, 203);
}
.inputRowOnAddPackageComponent{
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tokenNameInputContainer{
    margin-top: 200px;
    width: 100%;
    height: 100%;
}
.genreName{
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}
.genreListingRow{
    display: inline-block;
    /* width: 50%; */
    margin-bottom: 20px;

}
.suitableGenreDisplay{
    display: inline-block;
    padding: 15px 10px 15px 10px;
    border: 1px solid black;
    border-radius: 5px;
    margin-right: 10px;
}
.genreContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.termDetailsDisplayFieldOnAddPackageComponent{
    background: rgb(240, 240, 240);
    padding: 20px 10px 20px 10px;
    color: rgb(99, 99, 99);
    width: 100%;
    border-radius: 5px;
}
.selectedTermDetailsDisplayFieldOnAddPackageComponent{
    background: rgba(4, 163, 255, 0.2);
    padding: 20px 10px 20px 10px;
    color: rgb(29, 29, 29);
    width: 100%;
    border-radius: 5px;
}
#displaySelectedTermIndicator{
    color: rgb(0, 128, 203);
    font-size: 50px;
}
#hiddenSelectedTermIndicator{
    filter: opacity(0);
    font-size: 50px;
}
.packageContentDisplayOnAddPackageComponent{
    display: inline-block;
    width: 100%;
    color: rgb(99, 99, 99);
    background: rgb(240, 240, 240);
    border-radius: 5px;
    padding: 20px 10px 20px 10px;
}
.editSoldPackageConfirmationBox{
    position:absolute;
    background: #FFFFFF;
    width: 25vw;
    height: 30vh;
    display: flex;
   justify-content: center;
   align-items: center;
    z-index: 99;
    padding: 40px;
    top: 35vh;
    right: calc(40vw - 12.5vw);
    border: 1px solid rgb(160, 160, 160);
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgb(29, 29, 29);
}
.editSoldPackageConfirmationDisplay{
    width: 80%;
}
.btnRow{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.editSoldPackageCount{
    text-align: center;
    font-weight: 600;
    font-size: 1.3vw;
}
#editSoldPackageConfirmationBtn{
    background: rgb(210, 25, 25);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
    width: 40%;
    height: 50px;
}
#editSoldPackageConfirmationBtn02{
    width: 40%;
    background: rgb(0, 128, 203);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
.massEditGenreCheckListAlignment{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.massEditGenreCheckListItemAlignment{
    padding-left: 20px;
}
.msddEditGenrePackageListItemAlignment{
    padding-left: 20px;

}