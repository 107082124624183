.baseAreaOfUSGConfirmationBox{
   
    background: rgb(247, 247, 250);
}
#useUSGBtn{
    background: white;
    color : rgb(25, 189, 104);
    font-weight: 900;
    width: 30%;
    height: 100%;
}
#newUSGBtn {
    background: white;
    color : rgb(0, 128, 203);
    font-weight: 900;
    width: 30%;
    height: 100%;
}
#declineAddRoomOccupancyBtn {
    background: white;
    color : rgb(255, 75, 43);
    font-weight: 900;
    width: 30%;
    height: 100%;
}
.USGBtnRow{
    display: flex;
    justify-content: space-between;
    height: 70px;
}
.USGDisplayRow{
    height: 100px;
    background: white;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.USGContent{
    display: inline-block;
    font-weight: 700;
    color: rgb(84, 84, 84);
    
}
.USGTitle{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    color: rgb(255, 75, 43);
    
}
.attentionSignRow{
    width: 100%;
    display: flex;
    justify-content: center;
}
.attentionSign{
    background: rgb(255, 75, 43);
    border-radius: 5px;
    color: white;
    padding: 0px 10px 0px 10px;
    margin-bottom: 10px;
    font-weight: 600;
}
.USGBtnRow .MuiButton-label{
    display: initial;
}