

.base{
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    background-color: #b6b6b6

}

/* .loginBoard {
    padding:50px;
    padding-top: 100px;
    padding-bottom: 100px;
} */

.pictureArea {
    width:200px;
    background: url("loginImage.png");
    background-position: center;
}

#inputForm {
    /* margin:105px; */
    margin-top:150px;
    /* margin-left: 70px;;  */
    padding: 20px;
}

#loginContainer {
    margin-top: 200px;
}

#pictureArea {
    width: 655px;
    height:511px;
}
.hidden {
    opacity: 0;
}
.warning {
    color: rgb(250, 88, 88);
    
}

.loginBaseCard{
    padding-left: 15px;
    padding-right: 15px;
}
.spacingOnLoginPage{
    height: 20px;
    width: 100%;
}
.titleOnLoginPage{
    margin-bottom: 40px;
}