
#clickToRecoverBtn{
    width:100%;
    
    background: rgb(25, 189, 104);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
.groupingFixedHeightBox{
    border-radius: 5px ;
    /* border: 1px solid rgb(208, 208, 208); */
    padding: 20px;
    height: calc(70vh - 65px);
    overflow-y: scroll;
}
.genreNameBox{
    display: inline-block;
    /* width: 30%; */
}
.genreStatusBox{
    display: inline-block;
    /* width: 25%; */
}
.genreColorBox{
    display: inline-block;
    /* width: 25%; */
}
#colorPickerContainer{
    position: absolute;
    z-index: 2;
}
.colorRepresentField{
    position: relative;
}
/* NEW CSS */

.termContentBox{
    display: inline-block;
    /* width: 42%; */
}
.termStatusBox{
    display: inline-block;
    /* width: 14%; */
}
.termDetailsDisplayField{
    background: rgb(240, 240, 240);
    padding: 20px 10px 20px 10px;
    color: rgb(99, 99, 99);
    /* width: 85%; */
    border-radius: 5px;
}
.termDetailsEditingInputs{
    /* width: 86%; */
}
.baseTermBox{
    display: inline-block;
    /* margin-right: 20px; */
}
.createTermContentBox{
    /* width: 56%; */
}
.flexendAligner{
    justify-content: flex-end;
}