.baseAreaOfMarkReferralDetailsBox{
    position: fixed;
    top: 30%;
    right: 30%;
    margin: auto;
    background:white;
    width: 30%;
    z-index: 2;
    border-radius: 5px;
    box-shadow: 0px 0px 15px black;
    padding: 40px;
    color: rgb(43, 43, 43);
}
.shadeOfMarkReferralDetailsBox{
    position:fixed;
    top: 0%;
    right: 0%;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(43, 43, 43,0.5);
}
#confirmationBtnOnMarkReferralDetailsBox{
    width: 20%;
    height: 50px;
    background: rgb(0, 128, 203);
    color: white;
    margin-left: 20px;
}
.inputFieldOnMarkReferralDetailsBox{
    width: 75%;
    display: inline-block;
    
}
.inputsContainerOnMarkReferralDetailsBox{
    display: flex;
    align-items: center;
}