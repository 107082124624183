
.modifyStepsRowOnCreateMassTokenEdit{
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    justify-content: center;
}
.fixedHeightWindowOnCreateNewMassTokenEditBox{
    width: 100%;
    height: 40vh;
    overflow-y: scroll;
    display: flex;
}
#stepButtonOnCreateMassTokenEdit {
    /* width: 10%; */
    background: rgb(0, 128, 203);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
.loadingLabel{
    display: inline-block;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
}
.loadingContainerRow{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loadingContainer{
    display: flex;
    align-items: center;
}
#loadingCircle{
    color: rgb(0, 128, 203);
}
.MassTokenEditIDContainer{
    display: inline-block;
    /* width: 15%; */
    /* margin-right: 20px; */
    height: 100%;
}
.MassTokenEditDaysContainer{
    display: inline-block;
    /* width: 12%; */
    /* margin-right: 20px; */
    height: 100%;
}
.vAligner{
    display: flex;
    align-items: center;
}