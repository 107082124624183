.appHeader {
    height:60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0px 0px;
}

#exitBtn {
    position: absolute;
    right:12px;
    top: 12px;
    width: 35px ;
    height:35px;
     color: rgba(36, 36, 36, 0.7);
     transition: 0.3s;
}

#exitBtn:hover {
    color: rgba(36, 36, 36, 1);
}
#editBtn {
    position: absolute;
    left:15px;
    width: 32px ;
    height:32px;
     color: rgba(255, 255, 255, 0.7);
     transition: 0.3s;
}
#editBtn:hover {
    color: rgba(255, 255, 255, 1);
}
.sectionAligner Button{
    width: 80%;
    /* background: white; */
    
}
#component-disabled{
    color:black;
}
.selectInputs.editOccupancyInputLeft{
    width: 35%;
    margin-right: 15px;
}
.selectInputs.editOccupancyInputRight{
    width: 35%;
    margin-left: 15px;
}
#editOccupancyTutorInput{
    width: 80%;
}
.occupancyDetails{
    height: 52px;
}

.sectionHeaderWhite{
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 700;
}
.details{
    color: rgb(255, 255, 255);
    text-shadow: 0px 0px 1.5px #FFFFFF;
    font-size: 22px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 40px;
}
.detailsBlock{
    font-size: 35px;
    font-weight: 700;
    background: white;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.btnContainer{
    padding: 15px;
    width: 80%;
    background: white;
    border-radius: 5px;
}
#sectionDivider{
    margin-top: 0px;
}
.detailsCard{
    width:100%;
    height:57vh;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.detailsSubTitle{
    display: inline-block;
}
.detailsContent{
    display: inline-block;
    /* margin-left: 15px; */
}
.detailsDate{
    font-size: 40px;
    border-right: 1px solid white;
    padding-right: 10px;
    display: inline-block;
    line-height: 90%;
    margin-right: 10px;
}
.detailsDay{
    font-size: 22px;
    
}

.detailsGenreContent{
    font-size: 2.5vw;
    text-align: start;
    line-height: 100%;
    margin-top: 10px;
    /* text-decoration: underline; */
    border-bottom: 15px solid white;
}
.detailsAgeGroupContent{
    font-size: 2.3vw;
    text-align: start;
    line-height: 100%;
}
.detailsTutorContent{
    font-size: 1vw;
    text-align: center;
    margin-top: 20px;
    background: white;
    border-radius: 5px;
    padding-right: 10px;
    padding-left: 10px;
}
.detailsRoomContent{
    display: inline-block;
}
/* .detailsMonthContent{
    display: inline-block;
    font-size: 25px;
} */
.detailsDateContent{
    font-size: 40px;
    display: inline-block;
}

#editForm div{
    margin-top: 20px;
}

.participationBox1{
    width:100%;
    /* border: 1px solid rgba(138, 138, 138, 0.5); */
    border-radius: 5px;
    padding: 20px;
    background: rgb(105, 37, 37);
    margin-bottom: 20px;
    position: relative;
}
.participationBox2{
    width:100%;
    /* border: 1px solid rgba(138, 138, 138, 0.5); */
    border-radius: 5px;
    padding: 20px;
    background: rgb(25, 189, 104);
    margin-bottom: 20px;
}
.participationBox3{
    width:100%;
    /* border: 1px solid rgba(138, 138, 138, 0.5); */
    border-radius: 5px;
    padding: 20px;
    background: rgba(52, 52, 52, 1);
    margin-bottom: 20px;
    position: relative;
}
.customerNameInParticipationBox{
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: white;
    width: 20%;
}
.customerPackageInParticipationBox{
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: white;
    width: 30%;
}
.customerAttendanceDisplay{
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: white;
}

#customerParticipationIcon{
    font-size: 50px;
    color: white;
}
#customerParticipationYellowIcon{
    font-size: 50px;
    color: rgb(255, 198, 101);
}
.iconRow{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
}
.functionBarRow{
    height: 70px;
    width:100%;
    border-radius: 2px;
    box-shadow: 0px 0px 5px rgba(36, 36, 36, 0.3);
    margin-bottom: 20px;
    padding: 5px ;
    
}
.flexerOnRoomOccupancyIndividualBox{
    display: flex;
    justify-content: space-around;
}
.bookingCount{
    height: 60px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wordingBorder{
    /* border: 1px solid rgba(108, 108, 108, 1); */
    padding: 0px 5px 0px 5px;
    border-radius: 2px;
}
#newBookingBtn{
    height: 70px;
    width: 100%;
    background: rgb(0, 128, 203);
    color:white;
    font-weight: 900;
}
#markAttnBtn{
    height: 70px;
    width: 100%;
    background: rgb(255, 183, 59);
    color:white;
    font-weight: 900;
}

.fixedHeightScrollingArea{
    height: calc(80vh - 160px);
    overflow-y: scroll;
}
.baseAreaOfAddNewBookingWindow{
    position: fixed;
    top: 16%;
    right: 9%;
    margin: auto;
    background:white;
    width: 60%;
    /* height: 40%; */
    z-index: 2;
    border-radius: 5px;
    box-shadow: 0px 0px 15px black;
    padding: 40px;
}
#cxlOccupancyBtn{
    background: rgb(255, 38, 0);
    color: white;
    font-weight: 500;
    width: 82%;
}
#cxlOccupancyBtn2{
    background: rgb(189, 189, 189);
    color: white;
    font-weight: 500;
    width: 82%;
}
#editOccupancyBtn{
    background: white;
    font-weight: 500;
    width: 82%;
}
#editOccupancyBtn2{
    background: rgb(189, 189, 189);
    font-weight: 500;
    width: 82%;
}
.cxlClassBookingIcon{
     color: white;
     position: absolute;
     right: 10px;
     top: 10px;
     display: flex;
     align-items: center;
     transition: 0.2s;
     border-radius: 5px;
}
.cxlClassBookingIcon:hover{
    color: rgb(50, 50, 50);
    background: white;
}
.inlineDisplayOnOccupancyIndividualBox{
    display: inline-block;

}
.cxlReasonBox{
    background:rgb(255, 255, 255);
    box-shadow:0px 0px 5px rgb(18, 18, 18);
    border-radius: 5px;
    width: 15vw;
    position: absolute;
    z-index: 3;
    padding: 10px;
}
.cxlBoxTitleRow{
    display: flex;
    align-items: center;
    justify-content: center;
}
#cxlPointing{
    font-size: 30px;
    color: rgb(255, 255, 255);
}
.attentionSignOnCxlBox{
    background: rgb(255, 75, 43);
    border-radius: 5px;
    color: white;
    padding: 0px 10px 0px 10px;
    font-weight: 600;
}
#cxlBookingReasonSelectionForm{
    width: 80%;
}
.cxlBoxAligner{
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cxlConfirmRow{
    width: 100%;
    display: flex;
    justify-content: center;
}
.cxlConfirmRow2{
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
#cxlConfirmBtn{
    width: 80%;
    /* border: 3px solid rgb(255, 75, 43); */
    box-shadow: 0px 0px 5px rgb(131, 131, 131);
    color: rgb(255, 75, 43);
}
#cxlConfirmBtn2{
    width: 80%;
    /* border: 3px solid rgb(255, 75, 43); */
    box-shadow: 0px 0px 5px rgb(131, 131, 131);
    background: rgb(255, 75, 43);
    color: white;
}
.participationRow1{
    width:100%;
    /* border: 1px solid rgba(138, 138, 138, 0.5); */
    border-radius: 5px;
    padding: 20px;
    background: rgb(120, 120, 120);
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.participationRow2{
    width:100%;
    /* border: 1px solid rgba(138, 138, 138, 0.5); */
    border-radius: 5px;
    padding: 20px;
    background: rgb(105, 37, 37);
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.participationRow3{
    width:100%;
    /* border: 1px solid rgba(138, 138, 138, 0.5); */
    border-radius: 5px;
    padding: 20px;
    background: rgb(25, 189, 104);
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.participationRow4{
    width:100%;
    /* border: 1px solid rgba(138, 138, 138, 0.5); */
    border-radius: 5px;
    padding: 20px;
    background: rgba(52, 52, 52, 1);
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.customerIDInParticipationBox{
    font-weight: 800;
    color: white;
    font-size: 35px;
    margin-left: 10px;
    width: 12%;
}

.attnDisplayOnParticipationBox{
    width: 20%;
}
.printableAttnList{
    width: 100%;
    height: 100vh;
    z-index: 6;
    position: fixed;
    top: 0%;
    left: 0%;
    overflow-y: scroll;
    background: white;
}
#attnListBase{
    width: 100%;
    /* height: 100%; */
    display: flex;
    justify-content: center;
    background: white;
}
.attnListTable th{
    padding: 9px;
    border: 1px solid black;
    text-align: center;
}
.attnListTable td{
    padding: 9px;
    border: 1px solid black;
    text-align: center;
}
.attnListTable tfoot td{
    height: 50px;
    text-align: left;
    font-weight: 600;
}
.attnListTable{
    margin-bottom: 20px;
    width: 100%;
}
.a4Width{
    width: 27cm;
    padding-top: 20px;
}
@media print{
    
    .noBreak {
      break-inside: avoid;
    }
    .noPrint {
        display: none;
      }
    
  }

.functionBarBtnInlineOnRoomOccupancyIndividualBox{
    display: inline-block;
}
.customerPackageOfPrivateClassInParticipationBox{
    display: flex;
    justify-content: center;
    font-weight: 600;
    color: white;
    width: 45%;
}
