#systemLeftNavBar{
    background: white;
    border-right: 1px solid rgb(206, 206, 206);
    
}
#systemMainBG{
    background: white;
}
.systemNavBarDisplayArea{
    width: 100%;
    height: 91vh;
    overflow: hidden;
}
.systemNavBarTitleArea{
    padding-top: 15px;
    padding-bottom: 20px;
}
.systemNavBarTitle{
    font-size: 25px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}
.systemNavBtnArea{
    width: 100%;
    height: 93%;
    overflow-y: scroll;
}
.systemNavBtnLabel{
    display: inline-block;
    margin-left: 30px;
    font-size: 18px;
    font-weight: 500;
    /* padding-top: 5px; */
}
.systemNavBtn{
    display: flex;
    align-items: center;
    color: rgb(120, 120, 120);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border-radius: 5px;
    margin-top: 5px;
}
#systemNavBtnIcon{
    font-size: 30px;
    
}
.systemNavBtnActive{
    display: flex;
    align-items: center;
    color: rgb(2, 137, 255);
    background: rgba(0, 162, 255, 0.1);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border-radius: 5px;
    margin-top: 5px;
}
.systemMainDisplayArea{
    width: 100%;
    height: 91vh;
    background: white;
    overflow-y: scroll;
    padding-top: 10px;
    /* padding-left: 30px;
    padding-right: 30px; */
}