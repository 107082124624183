.incomeListingMainDisplayArea{
    width: 100%;
    height: 90vh;
    background: white;
    overflow-y: scroll;
    padding-top: 10px;
    border-radius: 5px;
    overflow-y: scroll;
    /* padding-left: 30px;
    padding-right: 30px; */
}
.incomeListingSideDisplayArea{
    width: 100%;
    height: 90vh;
}
.incomeListingSideDisplaySection{
    width: 100%;
    /* height: 100%; */
    margin-bottom: 30px;
    background: white;
    border-radius: 5px;
    overflow-y: scroll;
    padding: 20px;
}

#selectIncomeListingDateInputs{
    display: inline-block;
}
#selectIncomeListingDateInputContainer{
    display: inline-block;
    margin-bottom: 20px;
}
.incomeListingSideDisplayRowIndicator{
    /* border: 1px solid rgb(240, 240, 240); */
    /* padding: 10px 20px ; */
    /* border-radius: 5px ; */
    /* margin-bottom: 20px; */
    /* padding-bottom: 10px; */
    /* border-bottom: 30px solid rgb(237, 237, 237); */
}
.incomeListingSectionHeader{
    font-size: 22px;
    font-weight: 500;
    /* margin-top: 10px; */
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(240, 240, 240);
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
}
.confirmSelectDateBtnRow{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
#incomeListingConfirmBtn{
    background:rgb(0, 128, 203);
    color: white;
    font-weight: 700;
}
#incomeListingConfirmBtn:hover{
    background:rgb(0, 128, 203);

}
.incomeTotalDisplay{
    font-size: 2vw;
    font-weight: 700;
    display: inline-block;
    margin-right: 20px;
    color: rgb(60, 60, 60)
}
.incomeTotalCurrencyDisplay{
    color: rgb(148, 148, 148);
    font-weight: 600;
    /* display: inline-block; */
}
.incomeListingSelectIncomeTypeRow{
    width: 100%;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid rgb(240, 240, 240);
    margin-bottom: 10px;
}
.incomeTypeBtn{
    display: inline-block;
    /* padding-left: 20px; */
    padding-right: 40px;
}
.selectedTypeBtnDisplay{
    display: inline-block;
    border-bottom: 2px solid black;
    color: rgb(41, 41, 41);
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 20px;
    height: 100%;
}

.typeBtnDisplay{
    color: rgb(154, 154, 154);
    border-bottom: 1px solid rgba(240, 240, 240,0);
    height: 100%;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 20px;
}
.noPaymentWithinPeriodContainerRow{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(154, 154, 154);

}
.incomeListingMainAreaContainer{
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: calc(100% - 160px);
    overflow-y: scroll;
}
.paymentRecordRow{
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(240, 240, 240);
    display: flex;
    align-items: center;
}
.paymentIconBG{
    background: rgba(215, 241, 255,0.5);
    border-radius: 50%;
    padding: 15px ;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
}
#paymentListingIcon{
    font-size: 25px;
    color: rgb(0, 128, 203);
}
.paymentIconContainer{
    display: inline-block;
    margin-right: 20px;
}
.paymentPurchaseIDContainer{
    display: inline-block;
    width: 15%;
    margin-right: 20px;
    height: 100%;
}
.staffIDContainer{
    display: inline-block;
    width: 11%;
    margin-right: 20px;
    height: 100%;
}
.staffEmploymentDateContainer{
    display: inline-block;
    width: 19%;
    margin-right: 20px;
    height: 100%;
}
.paymentPurchaseID{
    font-size: 20px;
    font-weight: 600;
}
.paymentPurchaseIDTitle{
    color: rgb(154, 154, 154);
}
.paymentCustomerNameContainer{
    display: inline-block;
    width: 20%;
    margin-right: 20px;
    height: 100%;
}
#paymentDetailsBtn{
    background: rgb(250, 250, 250);
    color: rgb(69, 69, 69);
    border: 0px solid rgb(240, 240, 240);
    transition: 0.2s;
    border-radius: 30px;

}
#paymentDetailsBtn:hover{
    background: rgb(211, 211, 211);
    color: white;
    border-radius: 30px;
}
.paymentCountOnIncomeListing{
    display: inline-block;
}
#paymentTypeColorIndicator1{
    display: inline-block;
    height: 15px;
    color: rgb(255, 196, 0);
}
#paymentTypeColorIndicator2{
    display: inline-block;
    height: 15px;
    color: rgb(11, 173, 106);
}#paymentTypeColorIndicator3{
    display: inline-block;
    height: 15px;
    color: rgb(134, 209, 250);
}#paymentTypeColorIndicator4{
    display: inline-block;
    height: 15px;
    color: rgb(0, 128, 203);
}#paymentTypeColorIndicator5{
    display: inline-block;
    height: 15px;
    color: rgb(236, 115, 255);
}#paymentTypeColorIndicator6{
    display: inline-block;
    height: 15px;
    color: rgb(255, 145, 49);
}
.paymentTypeIndicator{
    
    display: flex;
    align-items: center;
    color: rgb(103, 103, 103);
    background: rgb(254, 254, 254);
    border-radius: 5px;
}
.staffIconIDAligner{
    display: flex;
    align-items: center;
}