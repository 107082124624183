.dateInformationBox{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 450px;
    background: white;
    z-index: 2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgb(92, 92, 92);
    white-space: nowrap;
    display: flex;
    align-items: center;
}
.infoBoxIconOn7Days{
    position: absolute;
    bottom: 30px;
    left: 0px;
    width: 70px;
    height: 70px;
    background: rgb(255, 255, 255);
    z-index: 2;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgb(43, 43, 43);
    display: flex;
    justify-content: center;
    align-items: center;
}
#infoBoxPopIcon{
    font-size: 50px;
}
.infoBoxMainContentArea{
    width: 350px;
    display: inline-block;
}
.closeInfoBoxOn7Days{
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    /* background: rgb(113, 48, 36); */
    color: rgb(245, 137, 118);
    transition: 0.2s;
}
.closeInfoBoxOn7Days:hover{
    /* background: rgb(255, 75, 43); */
    color: rgb(255, 75, 43);
}
.btnAlignerOn7Days{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lastWeekBtn{
    height: 185px;
    width: 50px;
    text-align: center;
    background: rgb(255, 255, 255);
    display: inline-block;
    transition: 0.2s;
}
.lastWeekBtn:hover{
    background: rgb(240, 240, 240);
}
.dateSquare{
    width: 50px;
    margin: 0px;
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
}
.monthDisplayRow{
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
}
.showWeekDayBtn01On7Days{
    height:100%;
    width: 50%;
    /* border: 1px solid rgb(255, 255, 255); */
    background: rgb(247, 247, 250);
    color: rgb(146, 145, 145);
    padding:5px;
    margin-top: 5px;

    /* display: flex;
    justify-content: center;
    align-items: center; */
    border-radius: 0px 0px 0px 0px;
    font-weight: 800;
    display: inline-block;
    text-align: center;
}
.showWeekDayBtn01On7Days.active{
    background: rgb(0, 161, 255);
    color: white;
}
.showWeekDayBtn02On7Days{
    height:100%;
    width: 50%;
    /* border: 1px solid rgb(255, 255, 255); */
    background: rgb(242, 242, 243);
    color: rgb(146, 145, 145);
    padding:5px;
    /* margin-top: 20px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    border-radius: 0px 0px 0px 0px;
    font-weight: 800;
    display: inline-block;
    text-align: center;
}
.contentRowFormattingOn7daysSchedules{
    padding: 0px;
}
.tableBodyOn7days{
    height: 80vh;
    width:auto;
    overflow-y: scroll;
    overflow-x: scroll;
        /* background: linear-gradient(
            to bottom,
            white,
            white 98%,
            rgb(216, 216, 216) 2%,
            rgb(216, 216, 216)
    
    
        );
        background-size: 100% 50px; */
    white-space: nowrap;
}
.dayScheduleCol{
    border-right: 1px solid black;
    height: 100%;
    display: inline-block;
    padding: 0px;
}
.cssBase04On7Days{
    height:10vh;
    background: white;
    /* display: flex; */
    /* justify-content: center; */
    overflow-y: scroll;
    overflow-x: scroll;
    padding: 0px;
    /* white-space: nowrap; */
}
.roomColOn7Days{
    /* width: 33.333333%; */
    height: 100%;
    position: relative;
    list-style-type: none;
    display: inline-block;
    border-right: 1px solid rgb(216, 216, 216);
}
.classCardOn7Days{
    width: calc(100% - 4px);
    height: calc(100% );
    border-radius: 5px;
    background: rgb(107, 156, 235);
    /* padding: 25px; */
    filter: brightness(95%);
    transition: 0.3s;
    display: flex;
    align-items: center;
    text-align: center;
    border-left: 0px solid white;

}
.classCardOn7Days:hover{
    /* transform: scale(1.04); */
    /* border-left: 15px solid rgb(255, 255, 255); */
    filter: brightness(100%);

    box-shadow: 0px 0px 10px rgb(171, 171, 171);
    filter: brightness(100%);
}
.classInfoTimeOn7Days{
    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-weight: 700;
    width: 100%;
    line-height: 15px;
}
.classInfoGenreOn7Days{
    color: white;
    font-size: 20px;
    font-weight: 800;
    /* display: ; */
}
.classroomIndicatorBlockOn7Days{
    /* height:199px;; */
    background: rgba(255, 255, 255,0);
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 8px;
    color: rgb(101, 101, 101);
}
.clone7DaysBtn{
    height: 40px;
    background: rgb(25, 189, 104);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: white;
    font-weight: 600;
    border-radius: 0px;
}
.clone7DaysBtnDisabled{
    height: 40px;
    background: rgb(228, 228, 228);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: rgb(152, 152, 152);
    font-weight: 600;
    border-radius: 5px;
}
.mainBodyTime{
    background: white;
}