
/* NEW CSS */
.baseAreaOfDisplayCxlOccupancyWindow{
    position: fixed;
    top: 10%;
    right: 5%;
    margin: auto;
    background:white;
    width: 88%;
    height: 85%;
    z-index: 2;
    border-radius: 5px;
    box-shadow: 0px 0px 15px black;
    padding: 40px;
    color: rgb(43, 43, 43);
}

.displayAreaForCxlOccupancy{
    /* background: grey; */
    height: 73vh;
    width: 100%;
    overflow-y: scroll;
    /* box-shadow: 0px 0px 5px rgb(50, 50, 50); */
    border-radius: 5px;
    padding-top: 10px;
    border: 1px solid rgb(228, 228, 228);
}
.displayAreaForCxlOccupancyOnListingCustomerPage{
    /* background: grey; */
    height: 73vh;
    width: 100%;
    overflow-y: scroll;
    /* box-shadow: 0px 0px 5px rgb(50, 50, 50); */
    border-radius: 5px;
    padding: 10px;
    border: 1px solid rgb(228, 228, 228);
}
.displayAreaForCxlOccupancyOnListingCustomerPage .paymentRecordRow{
    display: flex;
    justify-content: space-around;
}
.titleRowOfCxlOccupancyDisplayArea{
    width: 99%;
    padding-left: 5%;
    padding-right: 5%;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    margin-left: 0.5%;
}
.cxlOccupancyRow{
    margin-left: 0.5%;
    width: 99%;
    padding-left: 5%;
    padding-right: 5%;
    height: 90px;
    /* border: 1px solid black; */
    /* box-shadow: 0px 0px 5px rgb(50, 50, 50); */
    border-bottom: 1px solid rgba(174, 174, 174, 0.3);
    /* margin-bottom: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    border-radius: 5px;
    font-weight: 300;
    font-size: 18px;
}
.cxlOccupancyRow:hover{
    box-shadow: 0px 0px 5px rgb(50, 50, 50);
    font-weight: 400;
}
.occupancyStartingTime{
    display: inline-block;
    width: 10%;
}
.occupancyEndingTime{
    display: inline-block;
    width: 15%;
}
.occupancyRoom{
    display: inline-block;
    width: 15%;
}
.occupyType{
    display: inline-block;
    width: 15%;
}
.occupancyType{
    display: inline-block;
    width: 15%;
}
.occupancyTutorName{
    display: inline-block;
    width: 17%;
}
.occupancyCustomerCount{
    display: inline-block;
    width: 8%;
}
.occupancyID{
    display: inline-block;
    width: 5%;
}
.subtitle{
    display: inline;
    font-size: 15px;
    font-weight: 400;
    color: grey;
}
#exitBtnOnCxlOccupancyCustomerListingPage{
    margin-left: 20px;
}
.displayFlex{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}