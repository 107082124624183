.appHeader {
    height:60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0px 0px;
}

#exitBtn {
    position: absolute;
    right:12px;
    width: 35px ;
    height:35px;
     color: rgba(255, 255, 255, 0.7);
     transition: 0.3s;
}

#exitBtn:hover {
    color: rgba(255, 255, 255, 1);
}
#editBtn {
    position: absolute;
    left:15px;
    width: 32px ;
    height:32px;
     color: rgba(255, 255, 255, 0.7);
     transition: 0.3s;
}
#editBtn:hover {
    color: rgba(255, 255, 255, 1);
}
.scrollAreaAll{
    overflow: scroll;
}