
.packageNameInput02{
    display: inline-block;
    width: 20%;
}
.packageNameInput03{
    display: inline-block;
    width: calc((50% - 50px)/4);
}
#completeInputAttributeBtn{
    margin: 0px;
    width: 10%;
    height: 56px;
}
#completeInputAttributeBtnDeactivated{
    margin: 0px;
    width: 10%;
    height: 56px;
    color: grey;
}
.SKUDivider{
    margin-left: 5px;
    margin-right: 5px;
}
.inventoryProductName{
    display: inline-block;
    /* width: 15%; */
    margin-right: 20px;
    height: 100%;
}
.productNameAligner{
    display: flex;
    align-items: center;
}
.productNameContainer{
    display: inline-block;
    width: calc(21% - 20px);
    margin-right: 20px;
    height: 100%;
}
.inventoryProductSKU{
    display: inline-block;
    width: calc(14% - 20px);
    margin-right: 20px;
    height: 100%;
}
.inventoryProductQuantity{
    display: inline-block;
    width: calc(8.5% - 20px);
    margin-right: 20px;
    height: 100%;
}
.inventoryProductCategory{
    display: inline-block;
    width: calc(8.5% - 20px);
    margin-right: 20px;
    height: 100%;
}
.inventoryProductPrice{
    display: inline-block;
    width: calc(9% - 20px);
    margin-right: 20px;
    height: 100%;
}
.inventoryProductAttribute{
    display: inline-block;
    width: calc(40% - 100px);
    margin-right: 20px;
    height: 100%;
    /* border-left: 1px solid black; */
}
.inventoryProductRow{
    font-size: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(240, 240, 240);
    display: flex;
    align-items: center;
}
.productContent{
    font-size: 15px;
    font-weight: 600;
}
.productContentInlineDisplay{
    font-size: 15px;
    font-weight: 300;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid black;
    padding: 5px;
    border-radius: 5px;
}
.productContentInlineDisplayThin{
    font-weight: 300;
    display: inline-block;

}
.productIconBG{
    background: rgba(215, 241, 255,0.5);
    border-radius: 50%;
    padding: 10px ;
    display: flex;
    justify-content: center;
    align-items: center;
}
#productListingIcon{
    font-size: 15px;
    color: rgb(0, 128, 203);
}
.productIconContainer{
    display: inline-block;
    margin-right: 10px;
    margin-left: 20px;
}
.inventoryProductNameTitle{
    display: inline-block;
    width: calc(21% - 40px);
    margin-right: 20px;
    margin-left: 20px;
    height: 100%;
}
.inventoryProductTitleRow{
    font-size: 10px;
    color: rgb(72, 72, 72);
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(240, 240, 240);
    display: flex;
    align-items: center;
}
.inventoryProductAction{
    display: inline-block;
    width: 80px;
    margin-right: 20px;
    height: 100%;
}
.suitableProductAttributeDisplay02 {
    display: inline-block;
    padding: 15px 10px 15px 10px;
    border: 1px solid rgba(0,0,0,0.30);
    border-radius: 5px;
    margin-right: 10px;
    color: rgba(0,0,0,0.4);
}
#productActionBtn{
    font-size: 25px;
}
.productActionBtn{
    display: inline-block;
    transition: 0.2s;
    padding: 5px;
    border-radius: 5px;
}
.productActionBtn:hover{
    background: rgb(228, 228, 228);
    color: white;
}
.btnAligner{
    display: flex;
    justify-content: space-around;
    align-items: end;
}
.productManageArea{
    width: calc(100% - 10px);
    height: calc(100% - 70px);
    /* background: grey; */
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid rgb(240, 240, 240);
    border-radius: 5px;
    /* box-shadow: 0px 0px 5px rgb(121, 121, 121); */
    margin-left: 5px;
    overflow-y: scroll;
}
#newProductFormGoodIndicator{
    margin-top: 30px;
    color: rgb(0, 128, 203);
}
#newProductFormBadIndicator{
    margin-left: 30px;
    color: rgb(0, 128, 203);
    opacity: 0%;
}