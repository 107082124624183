.dimensionVisualizer {
    
    border: 3px solid white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#searchingBar {
    height: 170px;
    margin-bottom: 30px;
}

#functionBar {
    height: 754px;
}

/* .mainDBArea {
    height: 100px;
    margin-bottom: 10px;
} */

.tutorDisplayArea {
    overflow-y: scroll;
    /* height: 754px; */
    height: 61vh;
   
    
}
.tutorTableHead {
    /* border: 1px solid white; */
    /* border-radius: 5px; */
    /* background: white; */
    height: 60px;
    width:99%;
    margin-bottom: 10px;
    font-weight: 700;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    transition: 0.3s;
    margin-left: 0.55%;

}

.tutorSimplifiedCard {
    border: 1px solid white;
    border-radius: 2px;
    background: white;
    /* height: 60px; */
    width:99%;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 500;
    /* display: flex; */
    /* justify-content: space-evenly; */
    /* align-items: center; */
    transition: 0.1s;
    margin-left: 0.55%;
    box-shadow: 0px 15px 20px rgba(202, 202, 202, 0.2);

}
.tutorSimplifiedCard:hover{
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
}

.tutorSimInfo {
    display: inline-block;
    margin: 0px;
}
.tutorID{
    /* width: calc(10% - 10px); */
    width:7%;
}
.nameChi{
    width:13%;
}
.nameEng{
    width:13%;
    display: flex;
    align-items: center;
}
.sex{
    width:7%;
}
.lilSpace{
    margin-left: 10px;
}
.emStatus{
    width:10%;
}
.danceType{
    width:10%;
}
.nextClass {
    width: 32%;
}
/* .circleInitial {
    border-radius: 50%;
    background-color: sandybrown;
    height:40px;
    width:40px;
    background-color: sandybrown;
    display: inline-block;
    margin-right: 5px;;
} */
.tutorName{
    display: inline-block;
}
.detailsBtn {
    /* width: 12%; */
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: 10px;
}
.payrollBtn {
    /* width: 8%; */
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: 10px;

}

#nextClass{
    display: inline-block;
}

#sevenDays {
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    color: rgb(119, 118, 118);
}

.inlineDisplay{
    display: inline-block;
    margin-right: 14px;
}
#tableHeading {
    margin-left: 9.7px;
    margin-bottom: 30px;
    margin-top: 30px;
    border: 1px solid white;
    width:100%;
}
.topClass {
    position:fixed;
    top:0px;
    right:0px;
    width: 100%;
    height: 100%;
   
    z-index: 1;
    text-align:center;
    background: rgba(0, 0, 0, 0.8);
}
.displayInArea{
    position: fixed;
    top: 5%;
    right: 5%;
    margin: auto;
    background:white;
    width: 90%;
    height: 90%;
    z-index: 2;
    border-radius: 5px;
    overflow: scroll;
}
.topClass2 {
    position:fixed;
    top:0px;
    right:0px;
    width: 100%;
    height: 100%;
   
    z-index: 3;
    text-align:center;
    background: rgba(0, 0, 0, 0.8);
}
.displayInArea2{
    position: fixed;
    top: 5%;
    right: 5%;
    margin: auto;
    background:white;
    width: 90%;
    height: 90%;
    z-index: 4;
    border-radius: 5px;
    /* overflow-x: scroll; */
}
.aligner{
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-right: 50px; */
}
.flexEnd{
    display: flex;
    justify-content: center;
}
.customBtn{
    font-weight: 700;
    font-size: 120%;
    margin-left: 10px;
}
#newTutorBtn{
    height:80%;
    /* width: 15%; */
    /* margin: 20px; */
}
#tableHeader{
    border: 1px solid white;
    width:100%;
}
.tutorSimplifiedHeadCard {
    border: 1px solid white;
    border-radius: 2px;
    background: white;
    height: 60px;
    width:99%;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
    transition: 0.1s;
    margin-left: 0.55%;
    box-shadow: 0px 15px 20px rgba(202, 202, 202, 0.2);
    

}

#functionBarArea {
    border: 1px solid white;
    border-radius: 2px;
    background: white;
    /* height: 60px; */
    width:99%;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.1s;
    margin-left: 0.55%;
    box-shadow: 0px 15px 20px rgba(202, 202, 202, 0.2);
    padding:20px;
}
#searchIconAligner{
    padding:20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.searchBar {
    width: 60%;
    border-bottom: 1px solid gray;
    margin-right: 5%;
}
.tableEnder{
    display: flex;
    justify-content: center;
}
.tableEnderContent{
    font-weight: 600;
    color: gray;
    padding-top: 20px;;
}
.fixedHeightWindowContainerOnAddTutorBox{
    height: 76vh;
    width: 100%;
    overflow-y: scroll;
}