.staffListingArea{
    width: calc(100% - 10px);
    height: calc(100% - 70px);
    /* background: grey; */
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid rgb(240, 240, 240);
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgb(121, 121, 121);
    margin-left: 5px;
    overflow-y: scroll;
}
.createStaffArea{
    width: 100%;
    height: 100%;
    /* background: grey; */
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid rgb(240, 240, 240);
    border-radius: 5px;
    /* box-shadow: 0px 0px 5px rgb(121, 121, 121); */
    margin-top: -10px;
    position: absolute;
    z-index: 2;
    background: white;
    overflow-y: scroll;
}
.staffListingAreaTitleRow{
    font-size: 22px;
    font-weight: 500;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid rgb(240, 240, 240);
    display: flex;
    justify-content: space-between;
}
.staffListingAreaTitle{
    display: inline-block;
}
.staffListingAreaSubtitle{
    display: inline-block;
    font-weight: 400;
    font-size: 18px;
    color: rgb(121, 121, 121);
}
.systemMainDisplayAreaOnStaffManage{
    width: 100%;
    height: 91vh;
    background: white;
    overflow-y: scroll;
    padding-top: 10px;
    display: flex;
    justify-content: center;
}
#createStaffBtn{
    /* height: 50px; */
    /* width: 10%; */
    color: white;
    background: rgb(0, 128, 203);
    transition: 0.2s;
}

#cxlCreateStaffBtn{
    /* height: 50px; */
    /* width: 10%; */
    color: white;
    background: rgb(255, 75, 43);
    transition: 0.2s;
}
.staffListingAreaTitleRow Button {
    padding-right: 20px;
    padding-left: 20px;
}
.fixedHeightWindowOnCreateNewStaffBox{
    width: 100%;
    height: 58vh;
    overflow-y: scroll;
    display: flex;
    /* align-items: center; */
}
.fixedHeightWindowOnStaffDetailsDisplayBox{
    width: 100%;;
    height: calc(100% - 100px);
    padding-top: 20px;
}
.staffDetailsRow{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.staffDetailsContainer{
    width: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid grey; */
    padding: 20px;

    /* margin: 20px; */
}
.staffDetails{
    display: inline-block;
}
.staffDetailLabel{
    display: inline-block;
    margin-right: 30px;
}
.staffDetails{
    display: inline-block;
    background: rgb(240, 240, 240);
    color: rgb(99, 99, 99);
    width: calc(100% - 110px);
    padding: 16px 14px ;
    border-radius: 5px;
}
.staffDetailsSection{
    /* width: 32%; */
    height: 100%;
    /* margin: 20px; */
    box-shadow: 0px 0px 5px rgb(225, 225, 225);
    border-radius: 0px;
    display: inline-block;
    margin-bottom: 20px;
}
.staffDetailsSectionTitle{
    font-size: 25px;
    font-weight: 500;
    padding: 20px;
    border-bottom: 1px solid rgb(235, 235, 235);
}
.staffDetailsEditingInputs{
    width: calc(100% - 110px);
}
.editStaffSelectInput{
    width: calc(100% - 110px);
}
.staffRemarksDetails{
    display: inline-block;
    background: rgb(240, 240, 240);
    color: rgb(99, 99, 99);
    width: calc(100% - 110px);
    height: 170px;
    padding: 16px 14px ;
    border-radius: 5px;
    overflow-y: scroll;
}