
.addNewBookingWindowTitle{
    margin-bottom: 30px;
    color: rgb(46, 46, 46);
    display: inline-block;
}
.navDisplayRow{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

#addNewBookingWindowCloseBtn{
    font-size: 30px;
    color: rgb(84, 84, 84);
    transition: 0.2s;
}
#addNewBookingWindowCloseBtn:hover{
    color: rgb(0,0,0);
}
.searchRow{
    margin-top: 0px;
}
#searchingInput{
    width: 70%;
    margin-left: 10px;
}
#searchOptionInAddNewBookingWindow{
    width:15%;
}
#searchBtnOnAddNewBookingWindow{
    width:100%;
    height:100%;
}
#searchBtnContainerOnAddNewBookingWindow{
    width: calc(15% - 10px);
    height: 48px;
    display: inline-block;
}
.fixedHeightScrollingAreaOnAddNewBookingWindow{
    width: 100%;
    height: 50vh;
    margin-top: 10px;
    overflow-y: scroll;
    /* background: rgb(84, 84, 84); */
}
.noneRow{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(114, 114, 114);
    font-weight: 400;
}
.customerResultRow{
    
    display: flex;
    justify-content: center;
    height:50px;
    margin-bottom: 5px;
    background: rgb(114, 114, 114);
    color: white;
    font-weight: 500;
    border-radius: 5px;
    }
    
.customerDetails{
    display: inline-block;
    vertical-align: bottom;
}
.customerDetailsID{
    width: 15%;
}
.customerDetailsNAME{
    width: 20%;
}
.customerDetailsSEX{
    width: 10%;
}
.customerDetailsTEL{
    width: 20%;
}
.detailsAligner{
    height:100%;
    display: flex;
    align-items: center;
}
.selectBtnContainer{
    width: 15%;
    height: 100%;
}
#selectCustomerBtn{
    width: 100%;
    height:100%;
    color: white;
}
.searchResultTitle{
    margin-top: 10px;
}

.customerResultRow2{
    /* margin-top: 30px; */
    /* border: 1px solid rgba(46, 46, 46,0.3); */
    display: inline-block;
    justify-content: center;
    height:45px;
    width: 84%;
    margin-right: 10px;
    border-radius: 5px;
    background: rgb(46, 46, 46);
    color: white;
    font-weight: 600;
    }

    .searchRow2{
        margin-top: 0px;
        display: flex;
        align-items: center;
    }
.customerDetailDisplayRow{
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.contentBlockOfClassTokenOfCustomerOnAddBookingWindow{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(84, 84, 84);
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 32%;
    position: relative;
    transition:0.2s;
}
.contentBlockOfClassTokenOfCustomerOnAddBookingWindow:hover{
        box-shadow: 0px 0px 5px rgb(0, 128, 203);
        background: rgb(53, 53, 53);
}

.selectedPackageDisplayRow{
    /* margin-top: 30px; */
    /* border: 1px solid rgba(46, 46, 46,0.3); */
    display: flex;
    justify-content: center;
    height:100px;
    width: 99%;
    margin-right: 10px;
    border-radius: 5px;
    background: rgb(69, 69, 69);
    color: white;
    font-weight: 600;
    }
    .packageDetails{
        display: inline-block;
        vertical-align: bottom;
    }
    .packageID{
        width: 16%;
    }
    .packageNAME{
        width: 29%;
    }
    .packageTOKEN{
        width: 20%;
    }
    .packageENDDATE{
        width: 20%;
    }
#deselectPackageBtn{
    color: rgb(199, 199, 199);
    transition: 0.2s;
}
#deselectPackageBtn:hover{
    color: rgb(255, 75, 43);
}
#bookingSingleClassBtn{
    width: 49%;
    height: 70px;
    /* background: rgb(227, 227, 227); */
    box-shadow: 0px 0px 5px rgb(0, 128, 203);
    margin-left: 5px;
    color : rgb(0, 128, 203);
    font-weight: 900;
    margin-top: 10px;
    display: inline-block;
}
#bookingSingleClassBtn2{
    width: 49%;
    height: 70px;
    /* background: rgb(227, 227, 227); */
    /* box-shadow: 0px 0px 5px rgb(0, 128, 203); */
    margin-left: 5px;
    color : rgb(131, 131, 131);
    font-weight: 900;
    margin-top: 10px;
    display: inline-block;
}
#bookingMultipleClassBtn{
    width: 49%;
    height: 70px;
    /* background: rgb(227, 227, 227); */
    box-shadow: 0px 0px 5px rgb(25, 189, 104);
    color : rgb(25, 189, 104);
    font-weight: 900;
    margin-top: 10px;
    display: inline-block;
}
#bookingMultipleClassBtn2{
    width: 49%;
    height: 70px;
    /* background: rgb(227, 227, 227); */
    /* box-shadow: 0px 0px 5px rgb(25, 189, 104); */
    color : rgb(131, 131, 131);
    font-weight: 900;
    margin-top: 10px;
    display: inline-block;
}
.detailsAligner2{
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.confirmBtnRow{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-top: 20px;
}
#confirmAddBookingBtn{
    height: 100%;
    width: 20%;
    background: rgb(255, 75, 43);
    color: white;
    font-weight: 600;
}
.classOccupanciesWithSameUSGDisplay{
    height: 25.35vh;
    width: 100%;
    margin-top: 10px;
    overflow-y: scroll;
}
.listingTitleOfOccupancySameUSG{
    font-size: 1vw;
    font-weight: 800;
    color: white;
    text-align: center;
    display: inline-block;
}
.listingTitleOfOccupancySameUSG2{
    font-size: 1vw;
    font-weight: 800;
    color: white;
    text-align: center;
    text-align: center;
}

.listingDetailsOfOccupancySameUSG{
    font-size: 15px;
    font-weight: 600;
    color: white;
    text-align: center;
}
.alignGenreAgeGroupRow{
    display: flex;
    justify-content: center;
    width: 100%;
}

/* USEFUL CSS BELOW */
    .contentBlockOfClassOccupancySameUSG{
        /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
        background: rgb(84, 84, 84);
        border-radius: 5px;
        padding: 20px;
        margin-bottom: 10px;
        margin-left: 10px;
        display: inline-block;
        width: calc(25% - 10px);
        position: relative;
        transition:0.2s;
    }
    .contentBlockOfClassOccupancySameUSG:hover{
            box-shadow: 0px 0px 5px rgb(0, 128, 203);
            background: rgb(53, 53, 53);
    }


.addNewBookingWindowTitle{
    margin-bottom: 30px;
    color: rgb(46, 46, 46);
    display: inline-block;
}
.navDisplayRow{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

#addNewBookingWindowCloseBtn{
    font-size: 30px;
    color: rgb(84, 84, 84);
    transition: 0.2s;
}
#addNewBookingWindowCloseBtn:hover{
    color: rgb(0,0,0);
}

.fixedHeightScrollingAreaOnAddNewBookingWindow{
    width: 100%;
    height: 52vh;
    margin-top: 10px;
    overflow-y: scroll;
    /* background: rgb(84, 84, 84); */
}

.confirmBtnRow{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-top: 20px;
}
#confirmAddBookingBtn{
    height: 100%;
    width: 20%;
    background: rgb(255, 75, 43);
    color: white;
    font-weight: 600;
}
.inlineDisplay{
    display: inline-block;
    margin-right: 14px;
}
.customerRow1OnAttendancePage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(25, 189, 104);
    border-radius: 5px;
    padding: 30px 40px 30px 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 98%;
    position: relative;
    transition:0.2s;
    box-shadow: 0px 0px 10px rgb(25, 189, 104);
}
.customerRow2OnAttendancePage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(50, 50, 50);
    border-radius: 5px;
    padding: 30px 40px 30px 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 98%;
    position: relative;
    transition:0.2s;
    box-shadow: 0px 0px 10px rgb(255, 38, 0);
}

.previewContentBlockAligner{
    width: 100%;
    display: flex;
    align-items: center;
}
.customerNumOnAttnPage{
    text-align: left;
    font-size: 25px;
    font-weight: 800;
    color: white;
    display: inline-block;
    margin-right: 20px;
    margin-left: 40px;
    width: 10%;
}
.customerNameOnAttnPage{
    text-align: left;
    font-size: 25px;
    font-weight: 800;
    color: white;
    display: inline-block;
    margin-right: 20px;
    margin-left: 40px;
    width: 25%;
}
.customerAttnContent{
    /* text-align: center; */
    font-size: 18px;
    font-weight: 700;
    color: white;
    display: inline-block;
    margin-left: 20px;
    width: 38%;
}
.customerAttnContentAligner{
    width:100%;
    font-weight: 800;
    /* display: flex;
    justify-content: space-evenly; */
}
.presentIndicator{
    color: white;
    font-weight: 800;
}
.customerRow3OnAttendancePage{
    /* box-shadow: 0px 0px 5px rgb(189, 189, 189); */
    background: rgb(130, 130, 130);
    border-radius: 5px;
    padding: 30px 40px 30px 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: inline-block;
    width: 98%;
    position: relative;
    transition:0.2s;
    box-shadow: 0px 0px 10px rgb(40, 40, 40);
}