.sectionHeaderOfInvoicePreview{
    font-size: 20px;
    margin-bottom: 20px;
}
.packageDetailsOnInvoicePreview{
    display: inline-block;
    width: 33%;

}
.contentRowDividerOnInvoicePreview{
    background: #f0f0f0;
    width: 100%;
    height: 10px;
    margin-bottom: 20px;
}
.sectionRowDividerOnInvoicePreview{
    background: #f0f0f0;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
}
.invoiceSection{
    color: rgb(70, 70, 70);
}
.packageNameOnInvoicePreview{
    margin-bottom: 10px;
}
.sectionSummaryOfInvoicePreview{
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
}
.discountDetails{
    display: inline-block;
    width: 50%;
}
.termsHeaderOfInvoicePreview{
    font-size: 10px;
}
.termsOnInvoicePreview{
    font-size: 10px;
}
.inventoryProductAttributeOnInvoicePreview{
    display: inline-block;
    width: 100%;
    margin-right: 20px;
    height: 100%;
    font-weight: 400;
    margin-top: 5px;
}
.productContentInlineDisplayOnInvoicePreview{
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
    margin-right: 20px;
    /* margin-top: 5px; */
    /* padding: 5px; */
    border-radius: 5px;
}
#productDetailIconOnInvoicePreviewPage{
    margin-right: 10px;
}
.productAttributeAlignerOnInvoicePreview{
    display: flex;
    align-items: center;
}