.packageBarTitleRow{
    /* border: 1px solid black; */
    /* height: 10%; */
    display: flex;
    justify-content: center;
    font-size: 20px;

}
.packagePickingBar{
    height: 100%;
    width: 100%;
    margin-right: 20px;
    display: inline-block;
}
.packageBarTitle{
    margin: 10px;
    font-weight: 200;
}
.fixedHeightPackageUsageViewingPort{
   background: rgba(237, 237, 237, 0.3);
    border-left:1px solid rgba(189, 189, 189,0.3);
    height: 100%;
    width: 75%;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
}
.fixedHeightScrollingListingArea1{
    width: 100%;
    height: 77%;
    overflow-y: scroll;
}
.mainContentAreaOnCustomerPackageUsageHistory{
    width: 25%;
    height: 100%;
    display: inline-block
}
.packageBarDisplayArea{
    width: 100%;
    height: 92%;
    /* border: 1px solid black; */
    overflow-y: scroll;
    font-weight: 300;
    margin-right: 12px;
    padding-right: 20px;
    /* font-size: 18px; */
}
.packageRow{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(189, 189, 189,0.3);
    /* border-radius: 5px; */
    margin-top: 10px;
    position: relative;
    background: rgb(138, 138, 138);
    color: white;

    /* box-shadow: 0px 0px 10px rgb(184, 184, 184); */
}
.customerPackageUsageHistoryBlockTitleRow{
    display: inline-block;
    width: 100%;
}
.blockTitleRowOnCustomerPackageUsageHistory{
    width: 100%;
    font-weight: 300;
}
.contentFixSizePackagesBlockOnCustomerPackageUsageHistory{
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 5px rgb(189, 189, 189);
    padding-left: 20px;
    margin-top: 5px;
    height: 73.7vh;
}
.packageUsageRow{
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid rgba(189, 189, 189,0.3);
    margin-bottom: 20px;
    background: white;
    
}
.tokenUsageOnCustomerPackageUsageHistory{
    width: 10%;
    
}
.createdAtOnCustomerPackageUsageHistory{
    margin-left: 30px;
    width: 16%;
}
.start_timeOnCustomerPackageUsageHistory{
    width: 20%;
}
.genreOnCustomerPackageUsageHistory{
    width: 15%;
}
.tutorOnCustomerPackageUsageHistory{
    width: 15%;
}
.packageRowClicked{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(45, 45, 45, 1);
    /* border-radius: 5px; */
    margin-top: 10px;
    position: relative;
    background: rgb(60, 60, 60);
    color: white;
    /* box-shadow: 0px 0px 10px rgb(184, 184, 184); */
}
#packageInactive{
    position: absolute;
    right: 20px;
    color: rgba(0, 128, 203,1);
    text-shadow: 0px 0px 5px black;
}
#packageAvailable{
    position: absolute;
    right: 20px;
    color: rgb(25, 189, 104);
}
#packageNotAvailable{
    position: absolute;
    right: 20px;
    color:rgb(255, 170, 43);
}
.selectedPackageStatusRow{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid black;
    background: white;
    text-align: center;
}
.attnOnCustomerPackageUsageHistoryCxl{
    color: rgb(255, 38, 0);
    font-weight: 900;
    border: 1px solid rgb(255, 38, 0);
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
    width:3%

}
.attnOnCustomerPackageUsageHistoryLeave{
    color: rgb(255, 196, 0);
    font-weight: 900;
    border: 1px solid rgb(255, 196, 0);
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
    width:3%

}
.attnOnCustomerPackageUsageHistoryAbsent{
    color: rgb(142, 142, 142);
    font-weight: 900;
    border: 1px solid rgb(142, 142, 142);
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
    width:3%

}
.attnOnCustomerPackageUsageHistory{
    color: rgb(25, 189, 104);
    font-weight: 900;
    border: 1px solid rgb(25, 189, 104);
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
    width:3%

}
.attnOnCustomerPackageUsageHistoryBooking{
    color: rgb(0,128,203);
    font-weight: 900;
    border: 1px solid rgb(0,128,203);
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
    width:3%
}
.start_dateOnCustomerPackageUsageHistorySelected{
    width: 12%;
}
.attnOnCustomerPackageUsageHistoryTitle{
    /* color: rgb(255, 38, 0); */
    /* font-weight: 900; */
    /* border: 1px solid rgb(255, 38, 0); */
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
    width: 5%;
}
.listingAreaTitleRowOnCustomerPackageUsageHistory{
    margin-top: 20px;
    font-weight: 500;
}.fixedHeightScrollingListingArea2{
    width: 100%;
    height: 87%;
    overflow-y: scroll;
}
.siteOnCustomerPackageUsageHistory{
    width: 5%;
}
.tokenUsageOnCustomerPackageUsageHistoryTitle{
    width: 10%;
    margin-right: 0px
}