

.appHeader {
    /* padding-: 8px; */
    background-color: rgb(1, 44, 82);
}

.aligner{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.accountInfo {
    /* display: inline-block; */
    /* margin: 8px; */
    margin-right: 60px;
    margin-left: 15px;
}

.whiteHeading{
    color: rgb(253, 244, 243);
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    display: inline-block;
    font-size: 1rem;
}

.whiteSubHeading {
    color: rgb(253, 244, 243);
    font-weight: 500;
    line-height: 1.5;
    text-align: left;
    display: inline-block;
    font-size: 0.88rem;
}
#navbarDropdown{
    padding-left: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.contentArea {
    /* margin: 20px; */
    border: 3px solid white;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.bgArea {
    background-color: rgb(244, 244, 244);
    padding: 30px;
    height: calc(100% - 60px);
}
.container-fluid {
    height: 100vh;
}
.navigationBar {
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 30px;;

}
.navButton {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}


.currentLocation {
    background: rgb(0, 69, 109);
    border-bottom: 3px solid rgb(255, 255, 255);
}
.nonCurrentLocation {
    border-bottom: 3px solid rgba(248, 248, 248,0);
}
.navHeading {
    color: rgb(253, 244, 243);
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    display: inline-block;
    font-size: 1.5rem;
}

