.productNameContainerOnCustomerListing{
    display: inline-block;
    width: calc(25% - 60px);
    margin-right: 20px;
    height: 100%;
}
.inventoryProductSKUOnCustomerListing{
    display: inline-block;
    width: calc(25% - 60px);
    margin-right: 20px;
    height: 100%;
}
.inventoryProductQuantityOnCustomerListing{
    display: inline-block;
    width: calc(15% - 60px);
    margin-right: 20px;
    height: 100%;
}
.inventoryProductCategoryOnCustomerListing{
    display: inline-block;
    width: calc(15% - 60px);
    margin-right: 20px;
    height: 100%;
}
.inventoryProductPriceOnCustomerListing{
    display: inline-block;
    width: calc(20% - 60px);
    margin-right: 20px;
    height: 100%;
}
.inventoryProductAttributeOnCustomerListing{
    /* display: inline-block; */
    margin-top: 10px;
    margin-left: 20px;
    width: 100%;
    margin-right: 20px;
    height: 100%;
    /* border-left: 1px solid black; */
}
.productDetailsRowOnCustomerListing{
    width: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
}
.inventoryProductNameTitleOnCustomerListing{
    display: inline-block;
    width: calc(25% - 80px);
    margin-right: 20px;
    margin-left: 20px;
    height: 100%;
}
.inventoryProductRowOnCustomerListing {
    font-size: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(240, 240, 240);
    
}
.productManageAreaOnCustomerListing{
    width: calc(100% - 10px);
    height: calc(100%);
    /* background: grey; */
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid rgb(240, 240, 240);
    border-radius: 5px;
    /* box-shadow: 0px 0px 5px rgb(121, 121, 121); */
    margin-left: 5px;
    overflow-y: scroll
}
.staffListingAreaTitleOnCustomerListing{
    display: inline-block;
    width: 60%;
}