.processNoTokenBookingWindow{
    width: 71vw;
    height: 39vw;
    position: absolute;
    background: white;
    top:10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 2;
}
.blockTitleOnProcessNoTokenBookingWindow{
    font-weight: 800;
    height: 50px;
    text-align: center;
    font-size: 1.5vw;
    padding-top: 10px;
    /* padding-bottom: 10px; */
}
.previousNoTokenBookingRow{
    height: 20%;
    padding: 20px;
}
.previousNoTokenBookingDetailsCard{
    /* background: rgb(0, 161, 255); */
    border: 2px solid rgba(0, 80, 126, 1);
    height:100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.previousNoTokenBookingDetailsCol{
    display: inline-block;
    /* height: 100%; */
    width: 30%;
    color: rgba(0, 80, 126, 1);
    font-weight: 700;
}
.previousNoTokenBookingDetails{
    width: 100%;
    text-align: center;
}
.suitableTokenDisplayRow{
    width: 100%;
    height: calc(80% - 175px);
    padding: 20px;
    overflow-y: scroll;
}
.confirmBtnRowOnProcessNoTokenBookingWindow{
    width: 100%;
    display: flex;
    justify-content: center;
}
#confirmBtnOnProcessNoTokenBookingWindow{
    margin-top: 10px;
    padding: 10px;
    background:rgb(255, 75, 43);
    color:white;
}
.packageBlockOnProcessNoTokenBookingWindow{
    width: calc(33.33% - 20px);
    padding: 40px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px ;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    display: inline-block;
}
.selectedPackageBlockOnProcessNoTokenBookingWindow{
    width: calc(33.33% - 20px);
    padding: 40px;
    background: rgb(0, 128, 203);
    border-radius: 10px ;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    display: inline-block;
    box-shadow: 0px 0px 10px rgb(116, 203, 254);
}
.selectedPackageBlockOnProcessNoTokenBookingWindow .packageDetailsOnProcessNoTokenBookingWindow{
    color: white;
}
.selectedPackageBlockOnProcessNoTokenBookingWindow #packageIconOnProcessNoTokenBookingWindow{
    color: white;
}
#packageIconOnProcessNoTokenBookingWindow{
    font-size: 8vw;
}
.packageIconContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin-top: 20px; */
    margin-bottom: 40px;
}
.packageDetailsOnProcessNoTokenBookingWindow{
    text-align: center;
    font-weight: 900;
    margin-top: 10px;
}