.purchaseHistoryWindow{
    width:100%;
    height: 95%;
    overflow-y: scroll;
}
.blockTitleRow2{
    border-bottom:1px solid rgba(189, 189, 189,0.5);
    display: flex;
    align-items: center;
}
.PurchaseRecordSimplifiedRow{
    height: 90px;
    width: 100%;
    border: 1px solid rgba(189, 189, 189,0.3);
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding-left : 20px;
    font-weight: 400;
    color: rgba(87, 87, 87, 1);
}
.purchaseRecord_PackageSummary{
    width: 50%;
    text-align: center;
}
.purchaseRecord_ID{
    width: 4%;
    text-align: center;
    margin-right: 3%;
}
.purchaseRecord_IDTitle{
    width: 8%;
    text-align: center;
    margin-right: 3%;
}
.purchaseRecord_AmountPayable{
    width: 8%;
}
.purchaseRecord_AmountPayableTitle{
    width: 9%;
    text-align: center;
}
.purchaseRecord_PurchaseStatus{
    width: 8%;
    color: rgb(0, 128, 203);
    font-weight: 700;
}
.purchaseRecord_PurchaseUnpaidStatus{
    width: 8%;
    color: rgb(255, 170, 43);
    font-weight: 700;
}
.purchaseRecord_ProcessPurchaseStaffID{
    width: 3%;
    text-align: center;
}
.purchaseRecord_ProcessPurchaseStaffIDTitle{
    width: 7%;
    text-align: center;
}
.purchaseRecord_CreateDate{
    width : 15%;
    text-align: center;
}
.purchaseRecord_CreateDateTitle{
    width : 10%;
    text-align: center;
}
#PurchaseHistoryRetractRowPaidIcon{
    color: rgb(0, 128, 203);
    
}
#PurchaseHistoryRetractRowVoidIcon{
    color: rgb(169, 169, 169);
    
}
#PurchaseHistoryRetractRowUnPaidIcon{
    color: rgb(255, 170, 43);
    
}
.listHeadRow{
    padding-left: 20px;
    font-weight: 600;
    margin-top: 10px;
}
.purchaseRecord_PackageSummaryTitle{
    width: 46%;
    text-align: center;
}
.purchaseRecord_PurchaseStatusTitle{
    width: 7%;
    padding-left: 15px;
    /* text-align: center; */
}
.paymentSumUpSection{
    /* height: 350px; */
    width: 100%;
    border-right: 1px solid rgba(189, 189, 189,0.3);
    border-bottom: 1px solid rgba(189, 189, 189,0.3);
    border-left: 1px solid rgba(189, 189, 189,0.3);
    background: rgba(237, 237, 237, 0.3);
    padding: 20px;
    overflow-y: hidden;
}
.paymentRecordSimplifiedRow{
    height: 90px;
    width: 100%;
    border: 1px solid rgba(189, 189, 189,0.3);
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding-left : 20px;
    font-weight: 400;
    color: rgba(87, 87, 87, 1);
    background: white;
}
.noPaymentRecordRow{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.paymentID{
    width: 10%;
    margin-left: 20px;
}
.paymentProcessStaff{
    width: 10%;
}
.paymentMethod{
    width: 15%;
}
.paymentAmount{
    width: 15%;
}
.paymentDate{
    width: 25%;
}
.paymentIDTitle{
    width: 11.5%;
    margin-left: 20px;
}
#managePaymentBtn{
    width:10%;
    background: rgb(0, 128, 203);
    color: white;
    font-weight: 700;
    margin-top: 10px;
}
.paymentProcessStaffTitle{
    width: 11%;
}
.paymentMethodTitle{
    width: 16%;
}
.paymentDateTitle{
    width: 30%;
}
.paymentAmountTitle{
    width: 17%;
}
.paymentTitleRow{
    font-weight: 600;
}
.fixHeightDisplayPaymentWindow{
    height: 19vh;
    width: 100%;
    overflow-y: scroll;
}
#managePurchaseBtn{
    width:10%;
    background: rgb(0, 128, 203);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px
}
#clickToChooseNewTermsBtn2{
    /* width:10%; */
    background: rgb(0, 128, 203);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
.managePurchaseBtnRow{
    width: 100%;
    display: flex;
    justify-content: center;
}
.purchaseRecord_PurchaseStatusVoided{
    width: 8%;
    font-weight: 700;
    color: rgb(169, 169, 169);
}
.declineVoidPurchaseBox{
    z-index: 2;
    width: 60vw;
    height: 30vh;
    background: white;
    position: absolute;
    top: 4vh;
    left: 10vh;
    box-shadow: 0px 0px 40px black;
    border-radius: 5px;
    padding: 80px;
    font-size: 30px;
    font-weight: 600;
}
.noticeRow{
    width: 100%;
    display: flex;
    justify-content: center;
}
.BtnRow{
    margin-top: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
}
#backFromDeclineBtn{
    width: 50%;
    height: 50px;
    background: rgb(255, 75, 43);
    font-size: 20px;
    color: white;
}
#managePaymentVoidedBtn{
    width:10%;
    background: rgb(169, 169, 169);
    color: white;
    font-weight: 700;
    margin-top: 10px;
}
#managePurchaseVoidedBtn{
    width:10%;
    background: rgb(169, 169, 169);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
.paymentSumUpTopRow{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
}
.paymentSumUpTitle{
    font-size: 21px;
    font-weight: 600;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px;
}
.paymentSumUpCount{
    display: inline-block;
    color: rgb(130, 130, 130);
    margin-bottom: 5px;
}
.noPurchaseRow{
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.noPurchaseNotice{
    color: rgba(123, 123, 123, 0.5);
    font-size: 18px;
}