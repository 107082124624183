
/* NEw CSS */
#clickToRecoverBtn{
    width:100%;
    
    background: rgb(25, 189, 104);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
.groupingFixedHeightBox{
    border-radius: 5px ;
    /* border: 1px solid rgb(208, 208, 208); */
    padding: 20px;
    height: calc(70vh - 65px);
    overflow-y: scroll;
}
.genreNameBox{
    display: inline-block;
    /* width: 30%; */
}
.genreStatusBox{
    display: inline-block;
    /* width: 25%; */
}
.genreColorBox{
    display: inline-block;
    /* width: 25%; */
}
#colorPickerContainer{
    position: absolute;
    z-index: 2;
}
.colorRepresentField{
    position: relative;
}