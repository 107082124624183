.systemManagePageTitle{
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    color: rgb(63, 63, 63);
    display: flex;
    align-items: center;
}
#systemManPageTitleIcon{
    font-size: 35px;
    margin-right: 10px;
}
.siteDisplayArea{
    width: 100%;
    padding: 20px;
    /* background: rgb(240, 240, 240); */
    border: 1px solid rgba(206, 206, 206,0.4);
    border-radius: 5px;
    margin-bottom: 20px;
}
.siteTitle{
    font-size: 20px;
    font-weight: 500;
}
.siteInformationBox{
    border-radius: 5px ;
    /* border: 1px solid rgb(208, 208, 208); */
    padding: 20px;
    margin-bottom: 30px;
}
.siteKeyBoxOnSitesMan{
    /* margin-left: 100px; */
    width: 20%;
}
.detailsDisplayField{
    background: rgb(240, 240, 240);
    padding: 20px 10px 20px 10px;
    color: rgb(99, 99, 99);
    /* width: 60%; */
    border-radius: 5px;
}
.detailsEditingInputs{
    width: 100%;

}
.siteNameBoxOnSitesMan{
    width: 40%;
}
.siteKeyOnSitesMan{
    background-color: rgb(240, 240, 240);
    padding: 10px;
    color: rgb(99, 99, 99);
}
.openingTimeTitle{
    margin-top: 50px;
    margin-bottom: 20px;
}
.siteOpeningTimeLabelOnSitesMan{
    margin-bottom: 10px;
    /* width: 60%; */
    text-align: center;
}
.openingTimeBox{
    /* width: 14%; */
    display: inline-block
}
.classroomRow{
    margin-top: 10px;
    border: 1px solid rgba(206, 206, 206,0.4);
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.classroomsInformationBox{
    border-radius: 5px ;
    /* border: 1px solid rgb(208, 208, 208); */
    padding: 20px;
}
.classroomNameBox{
    display: inline-block;
    /* width: 50%; */
}
.classroomCapacityBox{
    display: inline-block;
    /* width: 30%; */
}
#clickToAddNewClassroomBtn{
    width:10%;
    background: rgb(0, 128, 203);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
#clickToEditClassroomBtn{
    width:100%;
    
    background: rgb(0, 128, 203);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
#clickToDelClassroomBtn{
    width:100%;
    
    background: rgb(255, 75, 43);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
.addClassroomRow{
    display: flex;
    justify-content: center;
    align-items: center;
}
.editClassroomBtn{
    display: inline-block;
    /* width: 20% */
}
.btnContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.containerOnSitesMan{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.delFailedNoticeWindow{
    position: absolute;
    width: 40vh;
    padding: 40px;
    z-index: 2;
    background: white;
    box-shadow: 0px 0px 20px black;
    border-radius: 5px;
    top: 30%;
    left: 35%;
    
}
#clickToBackBtn{
   
    background: rgb(0, 128, 203);
    color: white;
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
}
.editSiteBtn{
    display: inline-block;
    /* width: 15%;
    margin-left: 80px; */
}
.inputNote{
    color: rgb(123, 123, 123);
    font-weight: 300;
}

.allSiteBarContainer{
    width: 100%;
    overflow-x: scroll;
    padding-left: 10px;
}
.allSiteBar{
    display: flex;
    align-items: center;
}
.displayingSiteBtnOnSiteManage{
    
    padding-right: 20px;
}
.siteBtnDisplaying{
    padding:10px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.siteBtnDisplay{
    padding:10px;
    /* border-bottom: 2px solid black;
    border-top: 2px solid black; */
    font-weight: 400;
    display: flex;
    align-items: center;
}
.addSiteBtnDisplay{
    padding:10px;
    border-bottom: 2px solid rgba(206, 206, 206,0.0);
    border-top: 2px solid rgba(206, 206, 206,0.0);
    font-weight: 500;
    display: flex;
    align-items: center;
    transition: 0.2s;
}
.addSiteBtnDisplay:hover{
    border-bottom: 2px solid black;
    border-top: 2px solid black;
}
.openingTimeInput{
    width: 20%;
    margin-right: 100px;
}
.fixedHeightWindowOnCreateNewSiteBox{
    width: 100%;
    height: 55vh;
    overflow-y: scroll;
    display: flex;
}
.inlineDisplayNoMargin{
    display: inline-block;
}