
#tableHeading {
    margin-left: 9.7px;
    margin-bottom: 30px;
    margin-top: 30px;
    border: 1px solid white;
    width:100%;
}

#functionBarArea {
    border: 1px solid white;
    border-radius: 2px;
    background: white;
    /* height: 60px; */
    width:100%;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.1s;
    margin-left: 0.55%;
    box-shadow: 0px 15px 20px rgba(202, 202, 202, 0.2);
    padding:20px;
}

#searchIconAligner{
    padding:20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchBar {
    width: 60%;
    border-bottom: 1px solid gray;
    margin-right: 5%;
}

.showWeekDayBtn01{
    height:100%;
    width: 50%;
    /* border: 1px solid rgb(255, 255, 255); */
    background: rgb(247, 247, 250);
    color: rgb(146, 145, 145);
    padding:5px;
    margin-top: 20px;

    /* display: flex;
    justify-content: center;
    align-items: center; */
    border-radius: 5px 0px 0px 5px;
    font-weight: 800;
    display: inline-block;
    text-align: center;
}
.showWeekDayBtn01.active{
    background: rgb(0, 161, 255);
    color: white;
}
.showWeekDayBtn02.active{
    background: rgb(0, 161, 255);
    color: white;
}
.showWeekDayBtn02{
    height:100%;
    width: 50%;
    /* border: 1px solid rgb(255, 255, 255); */
    background: rgb(242, 242, 243);
    color: rgb(146, 145, 145);
    padding:5px;
    margin-top: 20px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    border-radius: 0px 5px 5px 0px;
    font-weight: 800;
    display: inline-block;
    text-align: center;
}
.customBtn{
    font-weight: 700;
    font-size: 120%;
    /* margin-left: 10px; */
}
#timetableArea{
    width:99%;
    height: 70vh;
    background: white;
    overflow-x: scroll;
    overflow-y: scroll;
}
.aligner{
    display: flex;
    justify-content: center;
}


/* old css class */
.cssBase01 .react-calendar{
        border: 0px solid black;
        margin-top: 30px;
}
.cssBase01 .react-calendar .react-calendar__tile {
    color: rgb(146, 145, 145);
    font-weight: 800;
}
.cssBase01 .react-calendar .react-calendar__tile--active {
    background: rgb(0, 161, 255);
    color: white;
    font-weight: 800;
}
.cssBase01 .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
    background: rgb(0, 161, 255);

}
.react-calendar__month-view__weekdays__weekday abbr{
    color: rgb(146, 145, 145);
    font-weight: 800;
}
.react-calendar__navigation button{
    color: rgb(146, 145, 145);

}
.react-calendar__navigation .react-calendar__navigation__label{
    font-weight: 900;
    font-size: large;
    color: rgb(74, 74, 74);
}
.cssBase01{
    height:calc(100vh - 90px);
    background: white;
    overflow-x: scroll;
    /* display: flex;
    justify-content: center; */
}
#leftCol{
    border-right: 1px solid  rgba(202, 202, 202, 0.5);
}

.cssBase02{
    /* width:100vh; */
    height: 100px;
    background: rgb(249, 249, 249);
    display: flex;
    justify-content: center;
}
.cssBase03{
    height: calc(100vh - 290px);
    background: white;
    /* display: flex; */
    /* justify-content: center; */
    overflow-y: scroll;
    overflow-x: scroll;
    /* white-space: nowrap; */
}
.cssBase04{
    height: 100px;
    background: white;
    /* display: flex; */
    /* justify-content: center; */
    overflow-y: scroll;
    overflow-x: scroll;
    /* white-space: nowrap; */
}
#tableBase{
    white-space: nowrap;
    /* padding-left: 40px;; */
}
#scheduleHead{
    border-bottom: 1px solid rgba(202, 202, 202, 0.5);
    width: auto;
    height: 15%;
    background: white;
    /* overflow-y: scroll; */
    padding-left: 0px;

}
#test{
    width: 10000px;
    height: 20px;;
    background: grey;
}
.scheduleHeadCol{
    /* width: 500px; */
    display: inline-block;
    height:85px;
    /* border-right: 1px solid rgba(202, 202, 202, 0.7); */
    /* border-bottom: 1px solid rgba(202, 202, 202, 0.5); */
    /* padding-left: 40px; */
    text-align: center;
    

}
.scheduleTimeCol{
    width: 100px;
    display: inline-block;
    height:85px;
    /* border-right: 1px solid rgba(202, 202, 202, 0.7); */
    /* border-bottom: 1px solid rgba(202, 202, 202, 0.5); */

    text-align: center;

}
.tableHeads{
    padding-top: 40px;
    font-size: 18px;
    font-weight: 700;
    color: rgb(73  , 74, 73);
    /* border-bottom: 1px solid rgba(202, 202, 202, 0.5); */
    display: inline-block;
    width:100%;
    padding-bottom: 15px;
}
#tableBase{
    padding-left: 0px;;
}
.tableBody{
    width:auto;
    height: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
    /* background: linear-gradient(
        to bottom,
        white,
        white 98%,
        rgb(216, 216, 216) 2%,
        rgb(216, 216, 216)


    );
    background-size: 100% 50px; */
    white-space: nowrap;
}
#tableHeadContainer{
    height:85px;
}

.mainBodyBG{
    background: linear-gradient(
        to bottom,
        white,
        white 49px,
       
        rgb(216, 216, 216) 1px,
        rgb(216, 216, 216)


    );
    background-size: 100% 50px;
    width:1225px;
    /* height: 2250px; */
    margin: 0px;
    display: inline-block;
    padding: 0px;
}
.mainBodyTime{
    width:100px;
    /* height: 2250px; */
    display: inline-block;
    margin: 0px;
    border-right: 1px solid rgb(216, 216, 216);
}
.timeBlock{
    height:50px;
    position: relative;
    list-style-type: none;
}
.time{
    display: inline-block;
    position: absolute;
    /* vertical-align: text-bottom; */
}
.roomCol{
    /* width: 300px; */
    height: 100%;
    position: relative;
    list-style-type: none;
    display: inline-block;
    border-right: 1px solid rgb(216, 216, 216);
}
.classBlock{
    /* height:199px;; */
    background: rgba(255, 255, 255,0);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
.timeLine{
    height:1px;
    width:10px;
    border-bottom:1px solid rgb(216, 216, 216);
    /* margin-right: ; */
    position: absolute;
    display: inline-block;
}
.classCard{
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    border-radius: 5px;
    background: rgb(107, 156, 235);
    padding: 25px;
    filter: brightness(95%);
    transition: 0.3s;
    display: flex;
    align-items: center;
    border-left: 0px solid white;

}
.classCard:hover{
    /* transform: scale(1.04); */
    border-left: 15px solid rgb(255, 255, 255);
    box-shadow: 0px 0px 10px rgb(171, 171, 171);
    filter: brightness(100%);
}
.classInfoTime{
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    font-weight: 700;
}
.classInfoGenre{
    color: white;
    font-size: 1.3vw;
    font-weight: 900;
    /* display: ; */
}
.classInfoAge{
    color: white;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
}

.classInfoTutor{
    background-color: white;
    color: rgb(107, 156, 235);
    border-radius: 5px;
    font-size: 20px;
    font-weight: 800;
    /* display: inline-block; */
    padding: 0px 5px 0px 5px;
    margin-right: 10px;
    width: 100%;
    overflow: hidden;
}
.classInfoCustomer{
    background-color: white;
    color: rgb(107, 156, 235);
    border-radius: 5px;
    font-size: 20px;
    font-weight: 800;
    display: inline-block;
    padding: 0px 5px 0px 5px;
    margin-right: 10px;
    width:100%;
    overflow: hidden;
}
.classInfoContainer{
    width:100%;
    position: relative;
}
.classInfoStudentCount{
    background-color: white;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 800;
    display: inline-block;
    padding: 0px 5px 0px 5px;
    margin-right: 10px;
    margin-top: 5px;
}
.functionBarBase{
    /* height: 100%; */
    width: 100%;
    background: rgb(247, 247, 250);
    padding: 20px;
    margin-top: 10px;
    border-radius: 5px;
}
.MuiFormControl-root.MuiTextField-root.Inputs.newOccupancyInputLeft{
    width: 45%;
    margin-right: 15px;
}
.MuiFormControl-root.MuiTextField-root.Inputs.newOccupancyInputRight{
    width: 45%;
    margin-left: 15px;
}
.MuiFormControl-root.selectInputs.newOccupancyInputRight{
    width: 45%;
    /* margin-left: 15px; */
}
.MuiFormControl-root.selectInputs.newOccupancyInputLeft{
    width: 45%;
    margin-right: 15px;
}
#submitBtn{
    background: #00A1FF;
    color: white;
    font-weight: 900;
    margin-top: 20px;
}
#newClassFormTitle{
    font-weight: 600;
    color:rgb(73  , 74, 73);
    text-align: center;
}
#addRentalBtn{
    width: 100%;
    /* height: 200px; */
    background:rgb(247, 247, 250);
    padding: 20px;
    margin-top: 20px;
    /* display: flex; */
    /* align-items: center; */
    border-radius: 5px;
}
.inlineDivider{
    height: 37px;
    /* margin-bottom: 20px; */
}
.spacerBtwAddOccupancyBtn{
    height: 20px;
}

#rentalBtn{
    background: rgb(255, 255, 255);
    /* color: #00A1FF; */
    color: black;
    font-weight: 900;
}
#rentalBtnDisabled{
    background: rgb(255, 255, 255);
    /* color: #00A1FF; */
    color: rgb(126, 126, 126);
    font-weight: 900;
}
#privateBtn{
    background: rgb(255, 255, 255);
    /* color: #00A1FF; */
    color: rgb(25, 189, 104);
    font-weight: 900;
    /* margin-top: 20px; */

}
#privateBtnDisabled{
    background: rgb(255, 255, 255);
    /* color: #00A1FF; */
    color: rgb(126, 126, 126);
    font-weight: 900;
    /* margin-top: 20px; */

}
#normalClassBtn{
    background: rgb(255, 255, 255);
    /* color: #00A1FF; */
    color: rgb(0, 128, 203);
    font-weight: 900;
    margin-top: 20px;
}
#normalClassBtnDisabled{
    background: rgb(255, 255, 255);
    /* color: #00A1FF; */
    color: rgb(126, 126, 126);
    font-weight: 900;
    margin-top: 20px;
}
.topInfoCol{
    padding: 20px;
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: 600;
}
#bigDate{
    display: inline-block;
}
#switchDayBtn{
    width:100px;
    height:50px;
    /* background:grey; */
    margin-left: 20px;
}
#lastDay{
    width:50%;
    height: 100%;
    background: rgb(246, 246, 246);
    border-radius: 5px 0px 0px 5px;
    display: inline-block;
    transition: 0.2s;
    border: 1px solid rgba(202, 202, 202, 0.5);
}
#lastDay:hover{
    /* transform: scale(1.05); */
    filter: brightness(0.9);
}
#nextDay:hover{
    filter: brightness(0.9);
}
#nextDay{
    width:50%;
    height: 100%;
    background: rgb(255, 255, 255);
    border-radius: 0px 5px 5px 0px;
    display: inline-block;
    transition: 0.2s;
}
.nextLastAligner{
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.baseAreaOfUSGConfirmationBox{
    position: fixed;
    top: 35%;
    right: 10%;
    margin: auto;
    background:white;
    width: 50%;
    /* height: 40%; */
    z-index: 2;
    border-radius: 5px;
    box-shadow: 0px 0px 15px black;
    padding: 40px;
}
.finishAttnTickingIndicator{
    color: white;
    position: absolute;
    right: -15px;
    top: -20px;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    transition: 0.2s;
    border-radius: 5px;
    font-weight: 700;
}
.flexer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.headcountDisplay{
    /* margin-left: 5px; */
    /* display: inline-block; */
}
.attnTickIndicator{
    margin-left: 5px;
    display: inline-block;
}
.showCxlOccupancyDisabled{
    font-size: 16px;
    border: 1px solid rgb(206, 206, 206);
    background: white;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    transition: 0.2s;
}
.showCxlOccupancy{
    font-size: 16px;
    border: 1px solid rgb(0, 128, 203);
    background: white;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    transition: 0.2s;
}
.showCxlOccupancy:hover{
    background: rgb(0, 128, 203);
    border: 1px solid rgb(0, 128, 203);
    color: white;
}
.showCxlOccupancyDisabled:hover{
    background: rgb(240, 240, 240);
}
.cxlOccupancyNumberCountDisabled{
    display: inline-block;
    font-size: 7px;
    background: rgb(62, 62, 62);
    border-radius: 50%;
    padding-left: 6px;
    padding-right: 6px;
    color: honeydew;
}
.cxlOccupancyNumberCount{
    display: inline-block;
    font-size: 7px;
    background: rgb(0, 128, 203);
    border-radius: 50%;
    padding-left: 7px;
    padding-right: 7px;
    color: honeydew;
}
#siteEditor{
    padding: 10px;
    width: 25%;
}
#siteEditor .MuiSelect-root{
    background: white;
    height: 15px;

}
.centerControlPanelContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}