/* 
.termContentBox{
    display: inline-block;
    width: 56%;
} */
/* .termStatusBox{
    display: inline-block;
    width: 14%;
}
.termDetailsDisplayField{
    background: rgb(240, 240, 240);
    padding: 20px 10px 20px 10px;
    color: rgb(99, 99, 99);
    width: 90%;
    border-radius: 5px;
}
.termDetailsEditingInputs{
    width: 91%;
}
.baseTermBox{
    display: inline-block;
    margin-right: 20px;
}
.createTermContentBox{
    width: 70%;
} */

/* NEW CSS */
.discountPropsBox{
    display: inline-block;
    /* width: 23%; */
}
.discountAmountBox{
    display: inline-block;
    /* width: 15%; */
}
.baseDiscountBox{
    display: inline-block;
    /* margin-right: 20px; */
}

.discountAmountDetailsDisplayField{
    background: rgb(240, 240, 240);
    padding: 20px 10px 20px 10px;
    color: rgb(99, 99, 99);
    width: 100%;
    border-radius: 5px;
}
.discountDateDetailsDisplayField{
    background: rgb(240, 240, 240);
    padding: 20px 10px 20px 10px;
    color: rgb(99, 99, 99);
    width: 50%;
    border-radius: 5px;
}
.discountStatusBox{
    display: inline-block;
    /* width: 15%; */
}
.discountAmountDetailsEditingInputs{
    /* width: 50%; */
}
.discountExDateDetailsEditingInputs{
    /* width: 52%; */
}